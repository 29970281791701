import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Sub Id',
    'Pub Id',
    'Offer'
];

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Sub Id': 'subid',
    'Pub Id': 'pub_id',
    'Offer': 'offer'
};

export const firstRowData = [
    { id: 1, name: 'Canceled Subscriptions', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 2, name: 'Cancellation %', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 3, name: 'Customer Called To Cancel', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 4, name: 'Blacklisted', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 5, name: 'Fake Customer', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 6, name: 'Ethoca Alert', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 7, name: 'Order Cancelled', fontSize: "12px", width: "180px", padding: "16px 8px 16px 8px",   hasSortField: true },
    { id: 8, name: 'Unknown Cancellation Reason', fontSize: "12px", width: "220px", padding: "16px 8px 16px 8px",   hasSortField: true }
];

export const fields = {
    "Canceled Subscriptions": "cancelled_subscriptions",
    "Cancellation %": "cancellation_percentage",
    "Customer Called To Cancel": "customer_called_to_cancel",
    "Blacklisted": "blacklisted",
    "Fake Customer": "fake_customer",
    "Ethoca Alert": "ethoca_alert",
    "Order Cancelled": "order_cancelled",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
} as const;

export const fieldsForCSV = {
    "Canceled Subscriptions": "cancelled_subscriptions",
    "Cancellation %": "cancelation_percentage",
    "Customer Called To Cancel": "customer_called_to_cancel",
    "Blacklisted": "blacklisted",
    "Fake Customer": "fake_customer",
    "Ethoca Alert": "ethoca_alert",
    "Order Cancelled": "order_cancelled",
    "Unknown Cancellation Reason": "unknown_cancellation_reason"
};
