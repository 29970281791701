import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const PayCards = () => {
    const { theme } = useContext(ThemeContext)!;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.8062 4.80078L11.4206 4.80078C10.4151 4.80078 9.59998 5.57671 9.59998 6.53387L9.59998 16.9324C9.59998 17.8896 10.4151 18.6655 11.4206 18.6655H27.8062C28.8117 18.6655 29.6268 17.8896 29.6268 16.9324V6.53387C29.6268 5.57671 28.8117 4.80078 27.8062 4.80078Z" fill={theme === 'light' ? "white" : "#17202E"} stroke={theme === 'light' ? "#171717" : "#EBEFF7"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.6062 8L8.22065 8C7.21514 8 6.40002 8.77593 6.40002 9.73309L6.40002 20.1316C6.40002 21.0888 7.21514 21.8647 8.22065 21.8647H24.6062C25.6117 21.8647 26.4269 21.0888 26.4269 20.1316V9.73309C26.4269 8.77593 25.6117 8 24.6062 8Z" fill={theme === 'light' ? "white" : "#17202E"}  stroke={theme === 'light' ? "#171717" : "#EBEFF7"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.4062 11.1992L5.02063 11.1992C4.01513 11.1992 3.20001 11.9752 3.20001 12.9323L3.20001 23.3309C3.20001 24.288 4.01513 25.0639 5.02063 25.0639H21.4062C22.4117 25.0639 23.2268 24.288 23.2268 23.3309V12.9323C23.2268 11.9752 22.4117 11.1992 21.4062 11.1992Z" fill={theme === 'light' ? "white" : "#17202E"} />
        <path d="M3.20001 16.3985L23.2268 16.3985M5.02063 11.1992L21.4062 11.1992C22.4117 11.1992 23.2268 11.9752 23.2268 12.9323V23.3309C23.2268 24.288 22.4117 25.0639 21.4062 25.0639H5.02063C4.01513 25.0639 3.20001 24.288 3.20001 23.3309L3.20001 12.9323C3.20001 11.9752 4.01513 11.1992 5.02063 11.1992Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default PayCards