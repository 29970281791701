import getSymbolFromCurrency from 'currency-symbol-map';
import { AffiliateReportData, AffiliateReportRowInterface } from '../types/affiliate-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function transformDataToCSVFormat(data: AffiliateReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
    const result: { [key: string]: string | number }[] = [];
    const groupingLevels = new Set<number>();
    const filtersList = ['Currency', ...filters!] 

    function processItem(item: AffiliateReportRowInterface, hierarchy: string[] = [], level: number = 0) {
      const baseItem: { [key: string]: string | number } = {
        clicks: item.clicks || '0',
        clicks_with_orders: item.clicks_with_orders || '0',
        partials: item.partials || '0',
        partials_percentage: Number(item.partials_percentage) ? (`${parseFloat(item.partials_percentage.toString()).toFixed(2)}%`) : '0.00%',
        declines: item.declines || '0',
        declines_percentage: Number(item.declines_percentage) ? (`${parseFloat(item.declines_percentage.toString()).toFixed(2)}%`) : '0.00%',
        sales: item.sales || '0',
        conv_percentage: Number(item.conv_percentage) ? (`${parseFloat(item.conv_percentage.toString()).toFixed(2)}%`) : '0.00%',
        partial_conv: Number(item.partial_conv) ? (`${parseFloat(item.partial_conv.toString()).toFixed(2)}%`) : '0.00%',
        sales_conv: Number(item.sales_conv) ? (`${parseFloat(item.sales_conv.toString()).toFixed(2)}%`) : '0.00%',
        sales_rev: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.sales_rev) ? Number(item.sales_rev).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
      };

      if (item.name || hierarchy.length > 0) {
        groupingLevels.add(level);
      }

      baseItem[`${filtersList[level]}`] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

      result.push(baseItem);

      Object.keys(namesEnum).forEach((key) => {
        const nestedKey = namesEnum[key];
        if (Array.isArray(item[nestedKey])) {
          item[nestedKey].forEach((nestedItem: AffiliateReportRowInterface) =>
            processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
          );
        }
      });
    }

    if (data.currency && Array.isArray(data.currency)) {
      // @ts-ignore
      data.currency.forEach((currency) => processItem(currency));
    }

    const groupingFields = Array.from(groupingLevels).map(
      (level) => `${filtersList[level]}`
    );

    return result.map((item) => {
      const transformedItem: { [key: string]: string | number } = {};
      groupingFields.forEach((field) => {
        transformedItem[field] = item[field];
      });
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[fieldsForCSV[key]];
      });
      return transformedItem;
    });
  }

  export default transformDataToCSVFormat;