import { Dayjs } from "dayjs";

export interface LineChartClonedData {
    data?: any | null;
    date_range?: number | null;
    start_date?: string | null | Dayjs;
    end_date?: string | null | Dayjs;
    expand_level?: string | null;
    group_sequence?: string[] | null;  
    id: null | string | number | undefined;
    type?: string | null;
    geo_name?: string[] | null;
    affiliate_name?: string[] | null;
    offer_name?: string[] | null;
    display_filters?: string[] | null;
}

export interface LineChartData {
    hour: number;
    [key: string]: string | number;
}

export enum LineChartPrefixes {
    'salesPerformance' = 'sales_performance_',
    'salesPace' = 'sales_pace_',
}