import React, { FC, ReactNode, createContext, useEffect, useState } from "react";
import OdinForgeService from "../../odinForgeService/OdinForgeService";
import Utils from "../../odinForgeService/Utils";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { findTimeZone } from 'timezone-support';
import { timeZoneAbbreviations } from "../../types/enums/Timezones";

export interface IUserContext {
  email: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
  permissions: string[];
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
  avatar: string | null;
  setAvatar: React.Dispatch<React.SetStateAction<string | null>>;
  name: string | null;
  setName: React.Dispatch<React.SetStateAction<string | null>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  nonCanonicalTimezone: string;
  setNonCanonicalTimezone: React.Dispatch<React.SetStateAction<string>>;
  tenantId: string | null | number;
  setTenantId: React.Dispatch<React.SetStateAction<string | null | number>>;
  isSuperAdmin: boolean;
  setIsSuperAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  superTenant: string | null;
  setSuperTenant: React.Dispatch<React.SetStateAction<string | null>>;
  superTenantList: { [key: string]: string } | null;
}

export const UserContext = createContext<IUserContext | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [email, setEmail] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [nonCanonicalTimezone, setNonCanonicalTimezone] = useState<string>("America/New_York");
  const [timezone, setTimezone] = useState<string>("America/New_York");
  const [tenantId, setTenantId] = useState<string | null | number>(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [superTenant, setSuperTenant] = useState<string | null>(null);
  const [superTenantList, setSuperTenantList] = useState<{ [key: string]: string } | null>({});


  const location = useLocation();

  useEffect(() => {
    const fetchProfileInfo = async () => {
      if (!location.pathname.startsWith("/dashboard")) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await OdinForgeService.instance().getProfileInfo();

        setNonCanonicalTimezone(response.data.timezone ?? 'America/New_York');

        let userTimeZone = response.data.timezone ?? 'America/New_York';

        if (timeZoneAbbreviations[userTimeZone]) {
          userTimeZone = timeZoneAbbreviations[userTimeZone];
        }

        const timeZone = findTimeZone(userTimeZone);

        if (timeZone) {
          userTimeZone = timeZone.name; // Canonical name
        }

        setTimezone(userTimeZone ?? "America/New_York");
        dayjs.tz.setDefault(timezone);
        
        setEmail(response.data.email);
        setPermissions(response.data.reports);
        setAvatar(response.data.avatar ?? null);
        setName(response.data.name);
        setAvatar(response.data.profile_picture ? `${process.env.REACT_APP_BASE_URL}/${response.data.profile_picture.url}` : null);
        setTenantId(response.data.tenant ?? null);
        setIsSuperAdmin(response.data.isSuperAdmin ?? false);
        setIsAdmin(response.data.isAdmin ?? false);
      } catch (error) {
        Utils.instance().onFailure("There was a problem fetching profile info.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const fetchTenantList = async () => {
      if (isSuperAdmin) {
          try {
            const response = await OdinForgeService.instance().getTenantList();
            setSuperTenantList(response.data)
            
          } catch (error) {
            Utils.instance().onFailure("There was a problem fetching profile info.");
          } finally {
          }
        }
    };

    fetchTenantList();
  }, [isSuperAdmin]);

  return (
    <UserContext.Provider
      value={{
        email,
        setEmail,
        permissions,
        setPermissions,
        avatar,
        setAvatar,
        name,
        setName,
        isAdmin,
        setIsAdmin,
        loading,
        setLoading,
        timezone,
        setTimezone,
        nonCanonicalTimezone,
        setNonCanonicalTimezone,
        tenantId,
        setTenantId,
        isSuperAdmin,
        setIsSuperAdmin,
        superTenant,
        setSuperTenant,
        superTenantList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
