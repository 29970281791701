import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import TableLoading from "../../../UI/loading/TableLoading";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { dateRangeList, generateRangeConfigs, rangeConfigs, toggleOptions } from "./utils/config";
import FilterBarVarianceReport from "../../../organism/filterbar/FilterBarVarianceReport";
import VarianceReportTable from "./components/VarianceReportTable";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { VarianceReporHourData, VarianceReporPartnerData, VarianceReportClonedData, VarianceReportData } from "./utils/types/variance-report.types";
import { DataRangesParamsList } from "../../../../types/enums/DataRangesParamsList";

dayjs.extend(utc);
dayjs.extend(timezone);

interface VarianceReportProps  {
    clonedData: VarianceReportClonedData
    passData: (data: VarianceReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const VarianceReport: React.FC<VarianceReportProps> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(false);
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = useState<string | Dayjs>(clonedData['start_date'] ? dayjs.tz(clonedData['start_date']) : dayjs.tz().startOf('day').subtract(1, "day").tz(timezone));
    const [endDate, setEndDate] = useState<string | Dayjs>(clonedData['end_date'] ? dayjs.tz(clonedData['end_date']) : dayjs.tz().startOf('day').tz(timezone));
    // const params = new URLSearchParams();
    const [data, setData] = React.useState<VarianceReportData[]>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.VarianceReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    ////////////////
    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<(VarianceReporHourData | VarianceReporPartnerData)[]>([]);
    const [transformedNamesEnumCSV] = useState<{ [key: string]: string }>({});

    const groupSequence = groupings;

    const reportConfigValues = useRef({ reportName, groupSequence });
    
    const [tableType, setTableType] = React.useState(clonedData['tableType']?clonedData['tableType']:'affiliates');

    const [ categories, setCategories ] = React.useState<string[]>([]);
    const [ isCategoryData, setIsCategoryData ] = React.useState(false);

    const [, setLoaderKey] = useState(0);
    
    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        // groupings: JSON.stringify(groupings),
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
        tableType: tableType,
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            // JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            // || 
            dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || tableType !== clonedData['tableType']
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            // previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate || 
            previousValues.tableType !== tableType
        )) {
            previousValuesRef.current = {
                // groupings: JSON.stringify(groupings),
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
                tableType: tableType,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate]);


    function generateParams() {
        const params = new URLSearchParams();

        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 0o0 - 1)));

        groupings.forEach((value) => {
            params.append('variance_categories[0]',value)
        })

        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

        return params
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push(value)
        })
        const dataToHash :object = {
            category: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
        }

        return dataToHash;
    }

    async function handleBlur(groupings:string[]) {
        setTableLoading(true);
        setData([]);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        
        const params = generateParams()!
        
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            OdinForgeService.instance().getVarianceReportData(params, clonedReportId)
                .then(async (result) => {
                    setTableLoading(false);

                    if (result && result.data && result.data.length !== 0){
                        
                        setData(result.data);
                        createHash(generateObjectToHash, result.data, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
        }
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
                tableType: tableType,
            },
            report_name: reportName
        }
    }
    
    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            tableType: tableType,
        }
        passData(dataToPass)
    }

    async function getFilterData() {
        setTableLoading(true);
        const formattedStartDate = formatLocalISO((startDate as Dayjs).set('second', 0o0));
        const formattedEndDate = formatLocalISO((endDate as Dayjs).set('second', 0o0 - 1));

        const dataRangeParam = DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList]
        
        const fetchData = () => {
            return OdinForgeService.instance().getVarianceReportFilterData(formattedStartDate, formattedEndDate, timezone, `${clonedReportId}-filters` , isSuperAdmin, superTenant, tenantId, dataRangeParam)
        }

        try {
            setIsCategoryData(true);
            const response = await fetchData()
            const data = (response.data!.categories! as string[])

            setCategories(data)

            if (groupings.length !== 0 && categories.length === 0) {
                setGroupings([])
            }
        } catch (error) {
            // Utils.instance().onFailure("Error fetching graph data");
        } finally {
            // handleBlur();
            setIsCategoryData(false);
            setTableLoading(false);   
        }
    } 

    useEffect(() => {
        getFilterData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant]);

     useEffect(() => {
        if (categories.length === 0) {
            setGroupings([])
        } else if (categories.length !== 0 && groupings.length === 0) {
            setGroupings([categories[0]])
            // handleBlur(categories)
        } else {
            handleBlur(groupings)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [categories]);

     useEffect(() => {
        handleBlur(groupings)
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [groupings]);

    function getDefault() {
        return <div className={'h-96 bg-slate-50 dark:bg-odin-dark-gray-darkest_bluish w-full justify-center items-center flex'}>
            {tableLoading ? <TableLoading /> : (
                <p className={'p-10 tablet-size:p-4 text-sm text-slate-500 dark:text-odin-dark-gray-bluish text-center'}>
                    {
                        isCategoryData || categories.length === 0 ? 'Select other date range to view the report' : groupings.length === 0 ? 'Please select a category to view the report' : 'Select other category or date range to view the report'
                    }
                </p>
            )}
        </div>;
    }

    const handleChange = (newValue: string) => {
        setTableType(newValue);
    };

    return (
        <div className={"pb-10"} >
            <div className="!p-6 tablet-size:!p-0">
            <FilterBarVarianceReport
                handleBlur={getFilterData}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                names={categories}
                title={ReportName.VarianceReport}
                //////////////////////// for CSV btn
                data={transformedTableDataCSV}
                fieldsForCSV={transformedNamesEnumCSV}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={transformedNamesEnumCSV}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                ////////////////////////
                getFilterData={getFilterData}
                //////////////////////// Toggle button Props
                selectedType={tableType}
                handleChange={handleChange}
                toggleOptions={toggleOptions}
                /////////////////////
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                // For datepicker
                rangeConfigs={rangeConfigs}
                generateRangeConfigs={generateRangeConfigs}
            />
            </div>
            {
                data && data.length !== 0 && !tableLoading ? (
                    <VarianceReportTable data={data} viewMode={tableType}  getDefault={getDefault} setTransformedTableDataCSV={setTransformedTableDataCSV} />
                ) : (
                    <div className="mt-6">
                        {getDefault()}
                    </div>
                )
            }
            
        </div>
    );
}

export default VarianceReport