import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const ProfileIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <>
      {
        theme === 'light' ? (
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_949_7172)">
            <rect x="1" y="1" width="36" height="36" rx="8" fill={theme === 'light' ? '#EFFDFA' : '#293342'} />
            <rect x="2" y="2" width="34" height="34" rx="7" stroke={theme === 'light' ? 'white' : '#495D80'} strokeWidth="2"/>
            <path d="M25.6666 26.5V24.8333C25.6666 23.9493 25.3154 23.1014 24.6903 22.4763C24.0652 21.8512 23.2173 21.5 22.3333 21.5H15.6666C14.7825 21.5 13.9347 21.8512 13.3096 22.4763C12.6844 23.1014 12.3333 23.9493 12.3333 24.8333V26.5" stroke={theme === 'light' ? '#0095AC' : '#1E89A5'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.9999 18.1667C20.8409 18.1667 22.3333 16.6743 22.3333 14.8333C22.3333 12.9924 20.8409 11.5 18.9999 11.5C17.159 11.5 15.6666 12.9924 15.6666 14.8333C15.6666 16.6743 17.159 18.1667 18.9999 18.1667Z" stroke={theme === 'light' ? '#0095AC' : '#1E89A5'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_d_949_7172" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_949_7172"/>
            <feOffset/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.898039 0 0 0 0 0.905882 0 0 0 0 0.921569 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_949_7172"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_949_7172" result="shape"/>
            </filter>
            </defs>
          </svg>
        ) : (
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_7791_385562)">
            <rect x="1" y="1" width="36" height="36" rx="8" fill="#293342"/>
            <rect x="2" y="2" width="34" height="34" rx="7" stroke="#495D80" strokeWidth="2"/>
            <path d="M25.6673 26.5V24.8333C25.6673 23.9493 25.3161 23.1014 24.691 22.4763C24.0659 21.8512 23.218 21.5 22.334 21.5H15.6673C14.7833 21.5 13.9354 21.8512 13.3103 22.4763C12.6852 23.1014 12.334 23.9493 12.334 24.8333V26.5" stroke="#1E89A5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.0007 18.1667C20.8416 18.1667 22.334 16.6743 22.334 14.8333C22.334 12.9924 20.8416 11.5 19.0007 11.5C17.1597 11.5 15.6673 12.9924 15.6673 14.8333C15.6673 16.6743 17.1597 18.1667 19.0007 18.1667Z" stroke="#1E89A5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_d_7791_385562" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_7791_385562"/>
            <feOffset/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.158863 0 0 0 0 0.16001 0 0 0 0 0.162305 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7791_385562"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7791_385562" result="shape"/>
            </filter>
            </defs>
          </svg>
        )
      }
      
    </>
  )
}

export default ProfileIcon