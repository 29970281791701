import React, { useContext } from "react";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import getSymbolFromCurrency from "currency-symbol-map";

interface MetricCardProps {
  icon: React.ReactNode;
  amount: string;
  bgColor?: string;
  label?: string;
  isDescription?: boolean;
  data?: any;
  currency?: string;
}

const DashboardMetricCard = ({
  icon,
  amount,
  bgColor,
  label,
  isDescription,
  data,
  currency,
}: MetricCardProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <div className="w-full">
      {icon && amount ? (
        <div className={`p-6 h-36 rounded-md w-full bg-backgroundColor-light-blue flex justify-between gap-4 items-center ${bgColor}`}>
          <div className="flex flex-col gap-4">
            <h2
              className="text-base font-medium"
              style={{
                color: themedColors.content,
              }}
            >
              {label}
            </h2>
            <div className="flex gap-3 items-center">
              <div>{icon}</div>
              <p 
                className="text-textColor-black text-2xl font-semibold"
                style={{
                  color: themedColors.content,
                }}
              >
                {currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''} {amount}
              </p>
            </div>
          </div>
          {isDescription && (
            <div className="flex flex-col gap-2">
              {
                data.partials && (
                  <div className="text-sm font-medium" style={{color: themedColors.content}}>
                    Partials: <span className="font-normal">{data.partials} {data.partials_rate ? `(${data.partials_rate}%)` : ""}</span>
                  </div>
                )
              }
              {
                data.sales && (
                  <div className="text-sm font-medium" style={{color: themedColors.content}}>
                    Partials: <span className="font-normal">{data.sales} {data.sales_rate ? `(${data.sales_rate}%)` : ""}</span>
                  </div>
                )
              }
              {
                data.declines && (
                  <div className="text-sm font-medium" style={{color: themedColors.content}}>
                    Partials: <span className="font-normal">{data.declines} {data.declines_rate ? `(${data.declines_rate}%)` : ""}</span>
                  </div>
                )
              }
            </div>
          )}
        </div>
      ) : (
        <div className={`p-6 h-36 rounded-md w-full bg-backgroundColor-light-blue flex justify-center items-center ${bgColor}`}>
          <p 
            className="p-1 text-textColor-light-gray text-base"
            style={{
              color: themedColors.content,
            }}
          >
            No Data
          </p>
        </div>
      )}
    </div>
  );
};

export default DashboardMetricCard;
