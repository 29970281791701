import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const ProductsIcon = () => {
    const { theme } = useContext(ThemeContext)!;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 20.6585L4.75 17.0396M1.695 18.9571L8 22.4784L14.305 18.9571M8 29.5V22.4714M14.5 25.2605V19.6823C14.4997 19.4377 14.4329 19.1975 14.3061 18.9858C14.1793 18.7741 13.9971 18.5982 13.7778 18.476L8.72222 15.6868C8.50264 15.5644 8.25355 15.5 8 15.5C7.74645 15.5 7.49736 15.5644 7.27778 15.6868L2.22222 18.476C2.00286 18.5982 1.82065 18.7741 1.69389 18.9858C1.56713 19.1975 1.50026 19.4377 1.5 19.6823V25.2605C1.50026 25.5051 1.56713 25.7453 1.69389 25.957C1.82065 26.1687 2.00286 26.3445 2.22222 26.4668L7.27778 29.256C7.49736 29.3783 7.74645 29.4428 8 29.4428C8.25355 29.4428 8.50264 29.3783 8.72222 29.256L13.7778 26.4668C13.9971 26.3445 14.1793 26.1687 14.3061 25.957C14.4329 25.7453 14.4997 25.5051 14.5 25.2605Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.25 7.65846L12.75 4.03956M9.695 5.95709L16 9.47837L22.305 5.95709M16 16.5V9.47139M22.5 12.2605V6.68226C22.4997 6.43771 22.4329 6.19752 22.3061 5.98579C22.1793 5.77406 21.9971 5.59824 21.7778 5.47597L16.7222 2.68684C16.5026 2.56444 16.2536 2.5 16 2.5C15.7464 2.5 15.4974 2.56444 15.2778 2.68684L10.2222 5.47597C10.0029 5.59824 9.82065 5.77406 9.69389 5.98579C9.56713 6.19752 9.50026 6.43771 9.5 6.68226V12.2605C9.50026 12.5051 9.56713 12.7453 9.69389 12.957C9.82065 13.1687 10.0029 13.3445 10.2222 13.4668L15.2778 16.256C15.4974 16.3783 15.7464 16.4428 16 16.4428C16.2536 16.4428 16.5026 16.3783 16.7222 16.256L21.7778 13.4668C21.9971 13.3445 22.1793 13.1687 22.3061 12.957C22.4329 12.7453 22.4997 12.5051 22.5 12.2605Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.25 20.6585L20.75 17.0396M17.695 18.9571L24 22.4784L30.305 18.9571M24 29.5V22.4714M30.5 25.2605V19.6823C30.4997 19.4377 30.4329 19.1975 30.3061 18.9858C30.1793 18.7741 29.9971 18.5982 29.7778 18.476L24.7222 15.6868C24.5026 15.5644 24.2536 15.5 24 15.5C23.7464 15.5 23.4974 15.5644 23.2778 15.6868L18.2222 18.476C18.0029 18.5982 17.8207 18.7741 17.6939 18.9858C17.5671 19.1975 17.5003 19.4377 17.5 19.6823V25.2605C17.5003 25.5051 17.5671 25.7453 17.6939 25.957C17.8207 26.1687 18.0029 26.3445 18.2222 26.4668L23.2778 29.256C23.4974 29.3783 23.7464 29.4428 24 29.4428C24.2536 29.4428 24.5026 29.3783 24.7222 29.256L29.7778 26.4668C29.9971 26.3445 30.1793 26.1687 30.3061 25.957C30.4329 25.7453 30.4997 25.5051 30.5 25.2605Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ProductsIcon