import React, { useContext } from 'react'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SelectChangeEvent } from '@mui/material';
import DashboardOverviewFilterButtons from './DashboardOverviewFilterButtons';
import DashboardSelectDropdown from './DashboardSelectDropdown';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import TableDateTimePicker from '../../../../UI/DatePickerComponent/TableDateTimePicker';
import ChartSelectDropdown from '../../../../UI/selection/ChartSelectDropdown';
import FilterButtonsLineChart from '../../../../UI/reportComponents/FilterButtonsLineChart';
import CustomActionButtons from '../../../../UI/actionButtons/actionButtons';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DashboardFilterBarProps {
    startDate: string | dayjs.Dayjs;
    endDate: string | dayjs.Dayjs;
    setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    dateRange: number;
    setDateRange: (newValue: number) => void;
    affiliateName: string[];
    getLineChartData: () => void;
    passData: (data: any) => void;
    clonedReportId: any;
    passId: (id: string | number | null | undefined) => void;
    setDeletePopup: (newValue: boolean) => void;
    deletePopup: boolean;
    showDelete: boolean;
    datesApplyButton: boolean;
    getFilterData: () => void;
    copyButton: boolean;
    reportName: string;
    setCopyButton: (newValue: boolean) => void;
    clonedData: any;
    enableFiltersApplyButton: boolean; // enable/disable the 'Apply' button
    filterConfig: {
      label: string;
      name: string;
      options: string[] | undefined;
      itemName: string[];
      handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
    }[];
    title: string;
    clearOptions: () => void;
    setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
    refreshKey?: number,
    freezeButton: boolean;
    setFreezeButton: (newValue: boolean) => void;
    generateConfigRequestBody: () => object;
    generateClonedData(reportId: number): void;
    data: any;
}

const DashboardFilterBar = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRange,
    setDateRange,
    affiliateName,
    getLineChartData,
    passData,
    clonedReportId,
    passId,
    setDeletePopup,
    deletePopup,
    showDelete,
    datesApplyButton,
    getFilterData,
    copyButton,
    reportName,
    setCopyButton,
    clonedData,
    enableFiltersApplyButton,
    filterConfig,
    title,
    clearOptions,
    setRefreshKey,
    refreshKey,
    freezeButton,
    setFreezeButton,
    generateConfigRequestBody,
    generateClonedData,
    data,
}: DashboardFilterBarProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  const placeholderList = {
    affiliateName: "All Affiliates",
    campaignName: "All Campaigns",
  }

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title}
        </div>

        <div>
          <TableDateTimePicker
            handleBlur={getFilterData}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            reportName={reportName}
          />
        </div>
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <ChartSelectDropdown
          filterConfig={filterConfig}
          getLineChartData={getLineChartData}
          enableFiltersApplyButton={enableFiltersApplyButton}
          clearOptions={clearOptions}
          placeholderCustomList={placeholderList}
        />

        <div className="flex flex-wrap gap-3 items-end">
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            data={data}
            // fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            // namesEnum={namesEnum}
            // disabledCSV={disabledCSV}
            // isCSV={isCSV}
            // transformDataToCSVFormat={transformDataToCSVFormat}
            // groupings={groupings}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
        </div>
      </div>
    </>
  )
}

export default DashboardFilterBar