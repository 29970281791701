import getSymbolFromCurrency from 'currency-symbol-map';
import { SalesPaceReportData, SalesPaceReportRowInterface } from '../types/sales-pace-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function transformDataToCSVFormat(
  data: SalesPaceReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined
) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = ['Currency', ...filters!] 

  function processItem(item: SalesPaceReportRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = {
      "Sales": item.total_sales ? item.total_sales?.toString() : '0',
      "Prior Period Sales": item.total_24_sales ? item.total_24_sales?.toLocaleString() : '0',
      "Revenue": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_revenue ? item.total_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Prior Period Rev": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_24_revenue ? item.total_24_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "AOV": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_aov ? item.total_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Prior Period AOV": (item.currency ? getSymbolFromCurrency(item.currency) : '')  + (item.total_24_aov ? item.total_24_aov?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'),
      "Revenue Change %": item.s1_24_percentage ? parseFloat(item.s1_24_percentage?.toString()).toFixed(2) + ' %' : '---',
    };

    const steps = ["s1", "s2"]; 
    steps.forEach((step, index) => {
      const stat = item;
      const prefix = step + '_';
      
      if (stat) {
        baseItem[`Step ${index + 1} Sales`] = stat[`${prefix}sales`] ? stat[`${prefix}sales`]?.toLocaleString() : '0';
        baseItem[`Step ${index + 1} Prior Period Sales`] = stat[`${prefix}24_sales`] ? stat[`${prefix}24_sales`]?.toLocaleString() : '0';
        baseItem[`Step ${index + 1} Revenue`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '')  +  (stat[`${prefix}revenue`] ? stat[`${prefix}revenue`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Prior Period Rev`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}24_revenue`] ? stat[`${prefix}24_revenue`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} AOV`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}aov`] ? stat[`${prefix}aov`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Prior Period AOV`] = (stat.currency ? getSymbolFromCurrency(stat.currency) : '') + (stat[`${prefix}24_aov`] ? stat[`${prefix}24_aov`]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00');
        baseItem[`Step ${index + 1} Revenue Change %`] = stat[`${prefix}24_percentage`] ? parseFloat(stat[`${prefix}24_percentage`]?.toString()).toFixed(2)  + ' %' : '---';
      }
    });

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    baseItem[`${filtersList[level]}`] = item.name && item.name !== "NaN" ? capitalizeSpecificWords(item.name) : '---';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: SalesPaceReportRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    // @ts-ignore
    data.currency.forEach((currencyItem) => processItem(currencyItem));
  }

  const groupingFields = Array.from(groupingLevels).map(
    (level) => `${filtersList[level]}`
  );

  return result.map((item) => {
    const transformedItem: { [key: string]: string } = {};

    // Ensure all required fields are included in the transformed item
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field] || '';
    });

    // Map fields for CSV based on fieldsForCSV object
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[key] || '';
    });

    // Add steps data to the final transformed item
    const steps = ["s1", "s2"];
    steps.forEach((step, index) => {
      transformedItem[`Step ${index + 1} Sales`] = item[`Step ${index + 1} Sales`] || '';
      transformedItem[`Step ${index + 1} Prior Period Sales`] = item[`Step ${index + 1} Prior Period Sales`] || '';
      transformedItem[`Step ${index + 1} Revenue`] = item[`Step ${index + 1} Revenue`] || '';
      transformedItem[`Step ${index + 1} Prior Period Rev`] = item[`Step ${index + 1} Prior Period Rev`] || '';
      transformedItem[`Step ${index + 1} AOV`] = item[`Step ${index + 1} AOV`] || '';
      transformedItem[`Step ${index + 1} Prior Period AOV`] = item[`Step ${index + 1} Prior Period AOV`] || '';
      transformedItem[`Step ${index + 1} Revenue Change %`] = item[`Step ${index + 1} Revenue Change %`] || '';
    });

    return transformedItem;
  });
}

export default transformDataToCSVFormat;
