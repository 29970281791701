import { DeclineResponseReport, DeclineResponseReportData, SubCategory } from "../../../../../types/decline-response-report.types";

function transformData(input: DeclineResponseReportData[], categoryKey: string): DeclineResponseReport[] {
    // const result =  Object.keys(input).map((gateway: string) => {
    //   // @ts-ignore
    //   const errors: SubCategory[] = input[gateway];
    //   const subCategoriesMap: { [reason: string]: SubCategory } = {};
  
    //   let totalCount = 0;
  
    //   errors.forEach(([reason, count] : any) => {
    //     const countNumber: number = typeof count === 'string' ? parseInt(count, 10) : count;
    //     totalCount += countNumber;
  
    //     if (subCategoriesMap[reason]) {
    //       subCategoriesMap[reason].count += countNumber;
    //     } else {
    //       subCategoriesMap[reason] = { reason, count: countNumber };
    //     }
    //   });
  
    //   const subCategories = Object.values(subCategoriesMap);
  
    //   return {
    //     [categoryKey]: gateway,
    //     count: totalCount,
    //     subCategories
    //   };
    // });


    const result = input.map((data: DeclineResponseReportData) => {
      const subCategoriesMap: { [reason: string]: SubCategory } = {};
  
      let totalCount = 0;
  
      data.decline_responses.forEach(({ response_title, response_count }) => {
        totalCount += response_count;
  
        if (subCategoriesMap[response_title]) {
          subCategoriesMap[response_title].count += response_count;
        } else {
          subCategoriesMap[response_title] = { reason: response_title, count: response_count };
        }
      });
  
      const subCategories = Object.values(subCategoriesMap);
  
      return {
        [categoryKey]: data.title,
        count: totalCount,
        subCategories
      };
    })

    return result
}

export default transformData;