import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const LineChartRange: { [key: string | number]: { startDate: dayjs.Dayjs, endDate: dayjs.Dayjs } } = {
  1: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().startOf('day') },
  2: { startDate: dayjs.tz().startOf('D').subtract(2, "d"), endDate: dayjs.tz().startOf('D').subtract(1, "d") },
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
        1: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().startOf('day').tz(timezone) },
        2: { startDate: dayjs.tz().startOf('day').subtract(2, "day").tz(timezone), endDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone) },
    };
  };