import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'Merchant Account',
    'Merchant Category',
    'GEO',
    'Offer',
    'Affiliate',
    'Subid',
    'Card Brand',
    'Gateway Type',
    'Product Category'
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_id',
    'Merchant Category': 'mid_name',
    'Offer': 'funnel_name',
    'Campaign Type': 'campaign_type',
    'Subid': 'c1',
    'Product Category': 'product_category',
    'Gateway Type': 'mid_category',
    'Card Brand': 'card_brand',
};

interface IRowData {
    id: number;
    name: string;
    fontSize: string;
    width: string;
    padding: string;
    isTips: boolean;
    tips: string;
    tips2: string;
    hasSortField: boolean;
    border?: boolean;
}

export const firstRowData: IRowData[] = [
    {
        id: 1,
        name: 'Cancels',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 3,
        name: 'Chargebacks',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 4,
        name: 'RDR',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: true,
        tips: "Chargebacks / (Chargebacks + Sales)",
        tips2: "Chargebacks % = ",
        hasSortField: true
    },
    {
        id: 5,
        name: 'Alerts',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 6,
        name: 'Ethoca Alerts',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 7,
        name: 'Gross Revenue',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: true,
        tips: "Refunds / (Refunds + Sales)",
        tips2: "Refunds % = ",
        hasSortField: true
    },
    {
        id: 8,
        name: 'Partial Refund',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 9,
        name: 'Full Refund',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true
    },
    {
        id: 10,
        name: 'Net',
        fontSize: "12px",
        width : "150px",
        padding: "16px 8px 16px 0px",
        isTips: false,
        tips: "",
        tips2: "",
        hasSortField: true,
        border: true
    }
];

export const fields = {
    "Cancels": "cancels",
    "Chargebacks": "chargebacks",
    "RDR": "rdr",
    "Alerts": "alerts",
    "Ethoca Alerts": "ethoca_alerts",
    "Gross Revenue": "gross_revenue",
    "Partial Refund": "partial_refund",
    "Full Refund": "full_refund",
    "Net": "net",

} as const;

export const fieldsForCSV = {
    "Cancels": "cancels",
    "Chargebacks": "chargebacks",
    "RDR": "rdr",
    "Alerts": "alerts",
    "Ethoca Alerts": "ethoca_alerts",
    "Gross Revenue": "gross_revenue",
    "Partial Refund": "partial_refund",
    "Full Refund": "full_refund",
    "Net": "net",
};