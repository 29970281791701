import React, {useContext, useEffect} from "react";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import "../../../../css/EmptyReport.css"
import "../../../../assets/icons/EmptyCheckbox"
import DashboardOverview from './DashboardOverview';
import CustomCardReportLayout from "../../../layout/CustomCardReportLayout/CustomCardReportLayout";
import { UserContext } from "../../../../context/UserContext/UserContext";
import { generateRangeConfigs } from "../../../../types/enums/RangeConfigs";
import { ConfigReport } from "../../../../types/report-config.types";

dayjs.extend(utc);
dayjs.extend(timezone);

const BaseDashboardOverview = () => {
    const [componentCount, setComponentCount] = React.useState<number>(0)
    const [clonedData, setClonedData] = React.useState<any>([{
        group_sequence: null,
        id: null,
        start_date: null,
        end_date: null,
        data:null
    }])

    const [refreshKey, setRefreshKey] = React.useState(0);

    const userContext = useContext(UserContext)!;
    const { timezone } = userContext;

    useEffect(() => {
        try {
            const response = OdinForgeService.instance().getReportConfig(ReportName.DashboardOverview)
            response.then((result:any) => {
                if (!result) {
                    return
                }
                const configs = result.data.data
                if (configs.length === 0)
                    setComponentCount(1)
                else {
                    setComponentCount(configs.length)
                    let configArray: any = []
                    configs.forEach((element: ConfigReport, index: number) => {
                        let parsedData = JSON.parse(element.group_sequence);
                        let start_date = dayjs.utc(parsedData.start_date).tz(timezone);
                        let end_date = dayjs.utc(parsedData.end_date).tz(timezone);

                        configArray[index] = {
                            group_sequence: JSON.parse(element.group_sequence).group_sequence,
                            id: element.id,
                            start_date: start_date,
                            end_date: end_date,
                            data: null,
                            type: element.type,
                            date_range: (JSON.parse(element.group_sequence).date_range?JSON.parse(element.group_sequence).date_range:0)
                        }
                        if (configArray[index] && configArray[index].date_range != null && configArray[index].date_range !== 0) {
                            const { startDate, endDate } = (generateRangeConfigs(timezone))[configArray[index]?.date_range!];
                            configArray[index].start_date = dayjs.utc(startDate).tz(timezone);
                            configArray[index].end_date =  dayjs.utc(endDate).tz(timezone);
                        }
                    })
                    setClonedData(configArray)
                }
            })
        }catch (error){
            console.log('error', error)
        }
    },[refreshKey, timezone])

    function updateClonedData(data: any) {
        setComponentCount((prevCount) => prevCount + 1)
        let updatedClonedData = [...clonedData]
        updatedClonedData[componentCount] = data
        setClonedData(updatedClonedData)
    }

    function deleteClonedData(id: string | number | null | undefined) {
        setComponentCount((prevCount) => prevCount - 1)
        let updatedClonedData = clonedData.filter((item:any) => item.id !== id)
        setClonedData(updatedClonedData)
    }

    return (
        <>
            {[...Array(componentCount)].map((element, index:number) => {
                return (
                    <CustomCardReportLayout key={clonedData[index].id} id={clonedData[index].id}>
                        <DashboardOverview
                            clonedData={clonedData[index]}
                            passData={updateClonedData}
                            passId={deleteClonedData}
                            setRefreshKey={setRefreshKey}
                            refreshKey={refreshKey}
                        />
                    </CustomCardReportLayout>
            )})}
        </>
    );
}

export default BaseDashboardOverview