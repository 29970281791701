import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

import eye from '../../../assets/eye.svg';
import eyeDark from '../../../assets/eyeDark.svg';
import eyeOff from '../../../assets/eye-off.svg';
import eyeDarkOff from '../../../assets/eyeDark-off.svg';

interface InputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label: string;
  name: string;
  placeholder: string;
  showPassword?: boolean;
  handleTogglePassword?: () => void;
  isPassword?: boolean;
  id?: string;
  isRequired?: boolean;
}

const Input = ({
  value,
  onChange,
  type = 'text',
  label,
  name,
  placeholder,
  showPassword,
  handleTogglePassword,
  isPassword = false,
  id,
  isRequired
}: InputProps) => {
  const { themedColors, theme } = useContext(ThemeContext)!;
  const [touched, setTouched] = useState(false);

  const showError = isRequired && touched && value.trim() === '';

  return (
    <label
      style={{ color: themedColors.content }}
      className={'flex flex-col'}
    >
      <span className='text-sm'>{label} {isRequired && <span className="text-[#FF3B30] inline text-sm">*</span>}</span>
      <div className='flex items-center'>
        <input
          type={type}
          name={name}
          className={
            `input bg-white dark:bg-odin-dark-blue-dark dark:!border-odin-dark-gray-extra_dark hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium ${showError ? '!border-[#FF3B30]' : ''}`
          }
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            fontSize: 16,
            WebkitBoxShadow: `0 0 0px 1000px ${
              theme === 'light' ? '#fff' : '#17202E'
            } inset`,
            WebkitTextFillColor: `${
              theme === 'dark' ? (value === '' ? '#677C9F' : '#F7EFEB') : ''
            }`,
            caretColor: `${theme === 'dark' ? '#F7EFEB' : '#000000'}`,
          }}
          id={id}
          onBlur={() => setTouched(true)}
        />
        {isPassword && (
          <div className='ml-[-40px] z-10 cursor-pointer'>
            <img
              src={
                showPassword
                  ? theme === 'light'
                    ? eye
                    : eyeDark
                  : theme === 'light'
                  ? eyeOff
                  : eyeDarkOff
              }
              onClick={handleTogglePassword}
              alt={''}
            ></img>
          </div>
        )}
      </div>
      {showError && (
        <span className="text-red-500 text-xs mt-[-5px]">{label} is required</span>
      )}
    </label>
  );
};

export default Input;
