
import React, { useContext } from "react";
import TableSelectDropdown from "../../UI/selection/TableSelectDropdown";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import CustomToggleButtonGroup from "../../UI/CustomToggleButtonGroup/CustomToggleButtonGroup";
import DatePickerLineChart from "../../UI/reportComponents/DatePickerLineChart";
import { VarianceReporHourData, VarianceReporPartnerData, VarianceReportClonedData } from "../../pages/reports/VarianceReport2/utils/types/variance-report.types";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import VarianceSelectDropdown from "../../pages/reports/VarianceReport2/components/VarianceSelectDropdown";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarVarianceReportProps {
  handleBlur: () => void;
  startDate: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  groupings: string[];
  setGroupings: (newValue: string[]) => void;
  names: string[];
  selectedGroupings: number;
  setTempCounter?(): number[];
  copyButton: boolean;
  freezeButton: boolean;
  showDelete: boolean;
  clonedReportId: string | number | null | undefined;
  setDeletePopup: (newValue: boolean) => void;
  passId: (id: string | number | null | undefined) => void;
  deletePopup: boolean;
  setFreezeButton: (newValue: boolean) => void;
  setCopyButton: (newValue: boolean) => void;
  clonedData: VarianceReportClonedData;
  generateConfigRequestBody: () => {
    filters: {
        group_sequence: string[];
        expand_level?: string;
        date_range: number;
        start_date: string | null;
        end_date: string | null;
    };
    report_name: ReportName;
  };
  generateClonedData(reportId: number): void;
  title: string;
  ///////////////////////
  startNumber?: number;
  setIsCollapsedByExandButton?: (value: boolean) => void;
  setIsManualExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  // Additional props for CSW btn
  data?: (VarianceReporHourData | VarianceReporPartnerData)[];
  fieldsForCSV?: { [key: string]: string };
  disabledCSV?: boolean;
  namesEnum?: { [key: string]: string };
  isCSV?: boolean;
  transformDataToCSVFormat?: (data: VarianceReporHourData[] | VarianceReporPartnerData[]) => {
    [key: string]: string | number | null;
  }[]
  getFilterData: () => void;
  datesApplyButton?: boolean;
  // Toggle props
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
  // ChartSelectDropdown Props
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number;
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  };
  generateRangeConfigs?: (timezone: string) => {
    [key: string | number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  };
}

const FilterBarVarianceReport = ({
  ////////////////////
  // 1. props for all components and PaceReport, AffiliatesReport
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  groupings,
  setGroupings,
  names,
  copyButton,
  freezeButton,
  showDelete,
  clonedReportId,
  setDeletePopup,
  passId,
  deletePopup,
  setFreezeButton,
  setCopyButton,
  clonedData,
  generateConfigRequestBody,
  generateClonedData,
  title,
  // Additional props for CSV btn
  data,
  fieldsForCSV,
  disabledCSV,
  namesEnum,
  isCSV,
  transformDataToCSVFormat,
  ///////////////////////
  getFilterData,
  datesApplyButton = true,
  // Toggle props
  selectedType,
  handleChange,
  toggleOptions,
   // ChartSelectDropdown Props
  setRefreshKey,
  refreshKey,
  rangeConfigs,
  generateRangeConfigs,
}: FilterBarVarianceReportProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title}
        </div>
        <DatePickerLineChart
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            getFilterData={getFilterData}
            datesApplyButton={datesApplyButton}
            customRangeConfigs={rangeConfigs}
            customGenerateRangeConfigs={generateRangeConfigs}
          />
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <div
          className={
            "flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
          }
        >
          <div className="hidden tablet-size:block tablet-size:ml-2">
            {
              selectedType && handleChange && toggleOptions && (
                <CustomToggleButtonGroup
                  selectedValue={selectedType}
                  onChange={handleChange}
                  options={toggleOptions}
                />
              )
            }
          </div>
          <div className="tablet-size:ml-4 tablet-size:relative tablet-size:top-[2px]">
            <VarianceSelectDropdown
                handleBlur={handleBlur}
                groupings={groupings}
                setGroupings={setGroupings}
                names={names}
                // maxSelections={maxSelections}
                multiple={false}
                dateRange={dateRange}
                label="Category"
                customComponent={
                  selectedType && handleChange && toggleOptions && (
                    <CustomToggleButtonGroup
                      selectedValue={selectedType}
                      onChange={handleChange}
                      options={toggleOptions}
                    />
                  )
                }
            />
          </div>
            
        </div>
        <div className="flex flex-wrap gap-3 items-end lala">
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            data={data}
            fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            namesEnum={namesEnum}
            disabledCSV={disabledCSV}
            isCSV={isCSV}
            transformDataToCSVFormat={transformDataToCSVFormat}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
        </div>
      </div>
    </>
  );
};

export default FilterBarVarianceReport;
