import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { BinRoutingReportData } from "../types/bin-routing-performace-report.types";

const transformDataToCSVFormat = (
    data: BinRoutingReportData[],
): {
    [key: string]: string | number;
  }[] => {
    if (!data || !data.length) {
        return [];
    }

    return data.map((report) => {
        const successRate = report.attempts
        ? ((report.success / report.attempts) * 100).toFixed(2) + '%'
        : '0%';

        return {
            'Routing Name': capitalizeSpecificWords(report.bin_routing_name),
            'Attempts': report.attempts,
            'Success': report.success,
            'Decline': report.decline,
            'Success Rate': successRate,
        }
    } );
}

export default transformDataToCSVFormat;