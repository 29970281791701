import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { names, namesEnum, options, optionsAttemptTxsType, tableHeadingNames } from "./utils/config";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import PaginationState from "./utils/types/pagination.types";
import { transformDataToCSVFormat } from "./utils/transformDataToCSVFormat";
import CardProcessingReportTable from "./components/CardProcessingReportTable";
import { CardProcessingReportClonedData, CardProcessingReportData, CardProcessingReportDataResponse } from "./utils/types/card-processing-report.types";
import FilterBarCardProcessingReport from "../../../organism/filterbar/FilterBarCardProcessingReport";
import { DataRangesParamsList, dateRangeList } from "../../../../types/enums/DataRangesParamsList";
import useMediaQuery from "../../../../hooks/useMediaQuery";

dayjs.extend(utc);
dayjs.extend(timezone);


interface CardProcessingReportInterface {
    clonedData: CardProcessingReportClonedData
    passData: (data: CardProcessingReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const CardProcessingReport: React.FC<CardProcessingReportInterface> = ({
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}): JSX.Element => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:['Merchant Name']);
    const [selectedGroupings] = React.useState(3)
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('D').subtract(1, "d").tz(timezone))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz().endOf('D').subtract(1, "d").tz(timezone))
    const [data, setData] = React.useState<CardProcessingReportData[]>(clonedData['data']?clonedData['data']:[]);
    const reportName = ReportName.CardProcessingReport;
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
    ////////////////
    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<CardProcessingReportData[]>([]);

    const groupSequence = groupings;

    const reportConfigValues = useRef({ reportName, groupSequence });

    const [loaderKey, setLoaderKey] = useState(0);

    const isUserInitiated = useRef(false);

    const [isFirstRender, setIsFirstRender] = React.useState(true);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
    })

    const [selectedTxsType, setSelectedTxsType] = React.useState(clonedData['selectedTxsType']?clonedData['selectedTxsType']:'all');
    const [selectedAttemptTxsType, setSelectedAttemptTxsType] = React.useState(clonedData['selectedAttemptTxsType']?clonedData['selectedAttemptTxsType']:'all');

    const isMobile = useMediaQuery('(max-width: 1023px)')

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
        selectedTxsType: selectedTxsType,
        selectedAttemptTxsType: selectedAttemptTxsType,
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || selectedTxsType !== clonedData['selectedTxsType']
            || selectedAttemptTxsType !== clonedData['selectedAttemptTxsType']
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate ||
            previousValues.selectedTxsType !== selectedTxsType ||
            previousValues.selectedAttemptTxsType !== selectedAttemptTxsType
        )) {
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
                selectedTxsType: selectedTxsType,
                selectedAttemptTxsType: selectedAttemptTxsType,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate, selectedTxsType, selectedAttemptTxsType ]);


    function generateParams() {
        const params = new URLSearchParams();

        params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));

        groupings.forEach((value) => {
            params.append('filters[0]',(namesEnum)[value])
        })

        params.append('currentPage', pagination.pageIndex ? pagination.pageIndex?.toString() : '1');
        params.append('pageSize', pagination.pageSize ? pagination.pageSize?.toString() : '10');

        params.append('timezone', timezone) 

        if (isSuperAdmin && superTenant) {
                superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
                tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

        params.append('card_processing_txs_type', selectedTxsType ? selectedTxsType : 'all');

        params.append('card_processing_attempt_txs_type', selectedAttemptTxsType ? selectedAttemptTxsType : 'all');

        return params;
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 59).format('YYYY-MM-DD HH:mm:ss'),
            dateRange: dateRange,
            selectedTxsType: selectedTxsType ? selectedTxsType : 'all',
            selectedAttemptTxsType: selectedAttemptTxsType ? selectedAttemptTxsType : 'all',
        }

        return dataToHash;
    }

    async function handleBlur() {

        if (groupings.length === 0) {
            setTableLoading(false);
            setData([]);
            return;
        }
        
        setTableLoading(true);
        const params = generateParams()!
        setData([]);
        const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
        if (cachedData){
            setTableLoading(false);
            setData(cachedData)
        } else {
            OdinForgeService.instance().getCardProcessingReportData(params, clonedReportId)
                // @ts-ignore
                .then(async (result: {data: CardProcessingReportDataResponse}) => {
                    setTableLoading(false);
                    if (result && result?.data?.status !== 'error') {
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: result.data.current_page,
                            pageSize: result.data.per_page,
                            totalPages: result.data.last_page,
                            totalItems: result.data.total,
                        }));
                        setData(result.data.data);

                        createHash(generateObjectToHash, result.data.data, reportName)
                        // Utils.instance().createHash(generateObjectToHash(), result.data, reportName)
                    } else {
                        setTableLoading(false);
                        setData([]);
                        // Utils.instance().onFailure('Sorry, no data found. Please select a different date.');
                    }
                })
                .catch((error) => {
                    if (error.code === 'ERR_CANCELED') {
                        setLoaderKey(prevKey => prevKey + 1);
                        console.log('Request canceled');
                    } else {
                        setTableLoading(false);
                        Utils.instance().onFailure('An error occurred while fetching the report');
                    }
                });
            }
    }

    const handlePageChange = (newPageIndex: number) => {
        isUserInitiated.current = true;
        setPagination((prev) => ({
          ...prev,
          pageIndex: newPageIndex + 1,
        }));
    };
    
    const handlePageSizeChange = (newPageSize: number) => {
        isUserInitiated.current = true;
        setPagination((prev) => ({
          ...prev,
          pageIndex: 1,
          pageSize: newPageSize,
        }));
    };

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
                selectedTxsType: selectedTxsType ? selectedTxsType : 'all',
                selectedAttemptTxsType: selectedAttemptTxsType ? selectedAttemptTxsType : 'all'
            },
            report_name: reportName
        }
    }
    
    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            selectedTxsType: selectedTxsType ? selectedTxsType : 'all',
            selectedAttemptTxsType: selectedAttemptTxsType ? selectedAttemptTxsType : 'all'
        }
        passData(dataToPass)
    }

    const handleGroupingsChange = (newValue: string[]) => {
        setGroupings(newValue);
    };

    useEffect(() => {
        isUserInitiated.current = true;
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, groupings, superTenant]);

    function updatePaginationOptions() {
        isUserInitiated.current = true;
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
    }

    const handleChangeTxsType = (newValue: string) => {
        isUserInitiated.current = true;
        setSelectedTxsType(newValue);
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
    };

    const handleChangeAttemptTxsType = (newValue: string) => {
        isUserInitiated.current = true;
        setSelectedAttemptTxsType(newValue);
        setPagination((prev) => ({
            ...prev,
            pageIndex: 1,
            pageSize: 10,
        }));
    };

    // Temporarly it should be last useEffect
    useEffect(() => {
        if (isFirstRender) {
          setIsFirstRender(false);
          return;
        }
      
        if (isUserInitiated.current) {
            isUserInitiated.current = false; // Reset the flag
            handleBlur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize,  isUserInitiated.current]);
    
    return (
        <div className={"pb-10"} >
            <div className="!p-6 tablet-size:!p-0">
                <FilterBarCardProcessingReport
                    handleBlur={updatePaginationOptions}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    groupings={groupings}
                    setGroupings={handleGroupingsChange}
                    selectedGroupings={selectedGroupings}
                    copyButton={copyButton}
                    freezeButton={freezeButton}
                    showDelete={showDelete}
                    clonedReportId={clonedReportId}
                    setDeletePopup={setDeletePopup}
                    passId={passId}
                    deletePopup={deletePopup}
                    setFreezeButton={setFreezeButton}
                    setCopyButton={setCopyButton}
                    clonedData={clonedData}
                    generateConfigRequestBody={generateConfigRequestBody}
                    generateClonedData={generateClonedData}
                    names={names}
                    title={ReportName.CardProcessingReport}
                    maxSelections={5}
                    //////////////////////// for CSV btn
                    data={transformedTableDataCSV}
                    fieldsForCSV={{grouping: groupings[0]}}
                    disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                    namesEnum={tableHeadingNames}
                    isCSV={true}
                    transformDataToCSVFormat={transformDataToCSVFormat}
                    // Label for select dropdown
                    label={"Filter by Group:"}
                    // Refresh cloneData
                    setRefreshKey={setRefreshKey}
                    refreshKey={refreshKey}
                    reportName={reportName}
                    // SearchRowProps
                    isSearch={false}
                    // searchTerm={searchTerm}
                    // setSearchTerm={setSearchTerm}
                    // handleSearch={handleSearch}
                    // txs type toggle props
                    selectedType={selectedTxsType}
                    handleChangeTxsType={handleChangeTxsType}
                    options={options}
                    toggleLabelWidth={isMobile ? '40px' : '61px'}
                    // attempt txs type toggle props
                    selectedAttemptTxsType={selectedAttemptTxsType}
                    handleChangeAttemptTxsType={handleChangeAttemptTxsType}
                    optionsAttemptTxsType={optionsAttemptTxsType}
                    attemptToggleLabelWidth={isMobile ? '40px' : '61px'}
                />
            </div>
            {
                data && data.length !== 0 && pagination ? (
                    <CardProcessingReportTable data={data} onPageChange={handlePageChange} paginationState={pagination} onPageSizeChange={handlePageSizeChange} setTransformedTableDataCSV={setTransformedTableDataCSV} heading={groupings[0] as keyof typeof tableHeadingNames} />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                groupings.length === 0 ? 'noFilter' : 'noData'
                            }
                        />
                    </div>
                )
            }
            
        </div>
    );
}

export default CardProcessingReport