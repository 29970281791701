import React, { useState } from "react";
import Selection from "./Selection";
import ChartSettingsButtonMobile from "./UI/ChartSettingsButtonMobile";
import FullScreenChartMobileModal from "./UI/FullScreenChartMobileModal";
import DefaultButton from "../buttons/DefaultButton";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import ConversionReportMobileModal from "../../pages/reports/conversationReport/components/ConversionReportMobileModal";
import { SelectChangeEvent } from "@mui/material";

interface ChartSelectDropdownProps {
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  }[];
  getLineChartData: () => void;
  enableFiltersApplyButton: boolean;
  clearOptions: (() => void);
  isDisableNotNeeded?: boolean;
  // FraudToggle props
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
  isFraudToggle?: boolean;
  reportName?: string;
  placeholderCustomList?: {
    affiliateName: string;
    campaignName: string;
  }
}

const ChartSelectDropdown = ({
  filterConfig,
  getLineChartData,
  enableFiltersApplyButton,
  clearOptions,
  isDisableNotNeeded = false,
  // FraudToggle props
  selectedType,
  handleChange,
  toggleOptions,
  isFraudToggle,
  reportName,
  placeholderCustomList,
}: ChartSelectDropdownProps) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsMobileModalOpen(true);
    document.body.style.overflow = "hidden";

    const baseDashboardContainer = document.getElementById(
      "baseDashboardContainer"
    );
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "100";
    }

    const mainContainer = document.getElementsByClassName(
      "main-content"
    )[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "100";
    }
  };

  const handleCloseModal = () => {
    setIsMobileModalOpen(false);
    document.body.style.overflow = "visible";

    const baseDashboardContainer = document.getElementById(
      "baseDashboardContainer"
    );
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "1";
    }

    const mainContainer = document.getElementsByClassName(
      "main-content"
    )[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "";
    }
  };

  const numberOfSelectedOptions = filterConfig.slice(0, 4).reduce((acc, filter) => {
    return acc + filter.itemName.length;
  }, 0);

  return (
    <>
      {/* Desktop view */}
      <div
        className={
          "hidden tablet-size:flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
        }
      >
        {filterConfig.map((filter) => {
          type PlaceholderKeys = keyof typeof placeholderList;

          const placeholderList = placeholderCustomList ? placeholderCustomList : {
            affiliateName: "All Affiliates",
            offerName: "All Offers",
            geoName: "All Geos",
          }
          
          return (
            <div key={filter.name} className="flex flex-col">
              <Selection
                label={filter.label}
                itemName={filter.itemName}
                handleSelectChange={filter.handleSelectChange}
                filterName={filter.options}
                multiple={true}
                placeholder={`${placeholderList[filter.name as PlaceholderKeys]}`}
              />
            </div>
          )
        })}
        <div className={"flex mr-2 items-end"}>
            <span>
              <DefaultButton disabled={!isDisableNotNeeded && !enableFiltersApplyButton} type='button' label='Apply'  className="flex-grow !text-base max-w-[168px] !hidden tablet-size:!flex h-9" onClick={getLineChartData} />  
            </span>
        </div>
      </div>

      {/* Mobile view */}
      <div className="block tablet-size:hidden">
        <div onClick={handleOpenModal}>
          <ChartSettingsButtonMobile isSelected={enableFiltersApplyButton} numberOfSelectedOptions={numberOfSelectedOptions ? numberOfSelectedOptions : 0} />
        </div>
        {isMobileModalOpen && reportName !== ReportName.ConversionReport && (
          <FullScreenChartMobileModal
            onClose={handleCloseModal}
            filterConfig={filterConfig}
            enableFiltersApplyButton={enableFiltersApplyButton}
            getLineChartData={getLineChartData}
            clearOptions={clearOptions}
            // FraudToggle props
            selectedType={selectedType}
            handleChange={handleChange}
            toggleOptions={toggleOptions}
            isFraudToggle={isFraudToggle}
          />
        )}
         { isMobileModalOpen && reportName === ReportName.ConversionReport && (
            <ConversionReportMobileModal
              onClose={handleCloseModal}
              filterConfig={filterConfig}
              enableFiltersApplyButton={enableFiltersApplyButton}
              getLineChartData={getLineChartData}
              clearOptions={clearOptions}
              // FraudToggle props
              selectedType={selectedType}
              handleChange={handleChange}
              toggleOptions={toggleOptions}
              isFraudToggle={isFraudToggle}
            />
         )
         }
      </div>
    </>
  );
};

export default ChartSelectDropdown;
