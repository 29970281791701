export const mockDataChargebackSummaryReport = {
    "Axcessms": [
        [
            "01256-StringName",
            100
        ],
        [
            "03455-StringName",
            6
        ],
        [
            "09988-StringName",
            2
        ],
        [
            "07531-StringName",
            1
        ],
    ],
    "Pixxel": [
        [
            "01256-StringName",
            80
        ],
        [
            "03455-StringName",
            2
        ],
        [
            "09988-StringName",
            1
        ],
        [
            "07531-StringName",
            1
        ],
    ]
}