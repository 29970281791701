import { ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { ReactNode, useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface PaginationNumberPageSelectProps {
    value: number;
    onChange: (event: SelectChangeEvent<number>, child: ReactNode) => void,
    min: number,
    max: number
}

const PaginationNumberPageSelect = ({value, onChange, min, max}: PaginationNumberPageSelectProps) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  // Define the range of pages to display
  const pageRange = 50; // Number of pages before and after the current page
  const startPage = Math.max(value - pageRange, min);
  const endPage = Math.min(value + pageRange, max);

  // Generate the array of page numbers to display
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      IconComponent={ChevronDownIcon}
      className={'!h-10 text-sm hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium'}
      sx={{
        '& .MuiSelect-icon, & .MuiSelect-iconOpen': {
          top: '11px',
          width: '18px',
          color: themedColors.content,
        },
        '& .MuiSelect-icon.MuiSelect-iconOpen': {
          width: '20px',
          color: '#0079FF',
        },
        '&.MuiOutlinedInput-root': { borderRadius: '6px' },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#0079FF' },
        '&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': { borderColor: '#0079FF' },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme === 'light' ? '#ACBFDF' : '#495D80'}`,
        },
        "& .MuiTypography-root": {
          fontSize: "14px !important",
        },
      }}
    >
      {/* Option to go to the first page if it's not in the current range */}
      {startPage > min && (
        <MenuItem
          value={min}
          sx={{
            color: themedColors.content,
            '&.MuiMenuItem-root .MuiTypography-root': {
              backgroundColor: themedColors.background_1,
            },
            '&.MuiMenuItem-root:hover, &.Mui-selected': {
              backgroundColor: themedColors.background_1,
            },
          }}
        >
          First Page
        </MenuItem>
      )}

      {pageNumbers.map((page) => (
        <MenuItem
          key={page}
          value={page}
          sx={{
            '&.MuiMenuItem-root .MuiTypography-root': {
              backgroundColor: themedColors.background_1,
              fontSize: '14px !important',
            },
            '&.MuiMenuItem-root:hover, &.Mui-selected': {
              backgroundColor: themedColors.background_1,
              fontSize: '14px !important',
            },
          }}
        >
          <ListItemText primary={page} style={{ fontSize: '14px !important' }} />
        </MenuItem>
      ))}

      {/* Option to go to the last page if it's not in the current range */}
      {endPage < max && (
        <MenuItem
          value={max}
          sx={{
            color: themedColors.content,
            '&.MuiMenuItem-root .MuiTypography-root': {
              backgroundColor: themedColors.background_1,
            },
            '&.MuiMenuItem-root:hover, &.Mui-selected': {
              backgroundColor: themedColors.background_1,
            },
          }}
        >
          Last Page
        </MenuItem>
      )}
    </Select>
  );
};

export default PaginationNumberPageSelect