import { DuplicateCustomerReportData } from "../types/duplicate-customer-report.types";

export const transformDuplicateCustomerData = (
  data: DuplicateCustomerReportData[]
): DuplicateCustomerReportData[] => {
  return data.map(item => {
    // Check if all details have product_vertical_is_same as 1 or "Yes"
    const allDetailsAreYes = item.details.every(
      detail => detail.product_vertical_is_same === 1 || detail.product_vertical_is_same === "Yes"
    );

    // Set main row's product_vertical_is_same to "Yes" if all details are "Yes"; otherwise "No"
    const updatedItem: DuplicateCustomerReportData = {
      ...item,
      product_vertical_is_same: allDetailsAreYes ? 1 : 0
    };

    return updatedItem;
  });
};