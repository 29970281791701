import React, { useState, useEffect, useContext } from 'react';
import {
    LineChart as RechartsLineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import dayjs, {Dayjs} from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import useMediaQuery from "../../../hooks/useMediaQuery";
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { lineColors } from '../../../types/enums/LineColors';
import LineChartSideBar from './LineChartSideBar';
import LineGraphTooltip from './LineGraphTooltip';
import './LineChart.css'
import capitalizeSpecificWords from '../../../helpers/capitalizeSpecificWords';
import replaceSpacesWithUnderscores, { replaceUnderscoresWithSpaces } from '../../../helpers/replaceSpacesWithUnderscores';
import { LineChartData } from '../../pages/reports/LineChart/types/line-chart.types';
import { NoDataFoundIcon } from '../../organism/DefaultTableMessage/DefaultTableMessage';

dayjs.extend(advancedFormat)

type DataKeys = string[];

interface LineChartProps {
    currentData: LineChartData[] | null;
    previousData: LineChartData[] | null;
    startDate: string | Dayjs;
    endDate: string | Dayjs;
    dataKeys: DataKeys;
    isDataFromBE?: boolean;
    geoName?: string[];
    affiliateName?: string[];
    offerName?: string[];
    errorMessage?: string;
    tooltipLabel?: string;
}

const LineChart = ({ currentData,  previousData , dataKeys, startDate, endDate, isDataFromBE, geoName, affiliateName, offerName, errorMessage, tooltipLabel}: LineChartProps) => {
    const [activeLine, setActiveLine] = useState<[string | null, number | null]>([null, null]);
    const [maxData, setMaxData] = useState<number>();

    const [processedCurrentData, setProcessedCurrentData] = useState<LineChartData[]>([]);
    const [processedPreviousData, setProcessedPreviousData] = useState<LineChartData[]>([]);

    const [isData, setIsData] = useState(true);

    const isMobile = useMediaQuery('(max-width: 425px)')

    const isTablet = useMediaQuery('(max-width: 1023px)')

    const isBigScreen = useMediaQuery('(max-width: 1440px)')

    const { themedColors, theme } = useContext(ThemeContext)!;

    const [maxForCurrentDate, setMaxForCurrentDate] = useState<Record<string, number>>({});
    const [maxForPreviousDate, setMaxForPreviousDate] = useState<Record<string, number>>({});

    const hasAffiliateNames = affiliateName && affiliateName.length > 0;
    const hasOfferNames = offerName && offerName.length > 0;
    const hasGeoNames = geoName && geoName.length > 0;

    const [visibleLines, setVisibleLines] = useState(() => {
        // Define categories based on conditions
        const categories = {
            total: !hasGeoNames && !hasAffiliateNames && !hasOfferNames,
            geo: hasGeoNames && !hasAffiliateNames && !hasOfferNames,
            affiliate: hasAffiliateNames && !hasOfferNames,
            offer: hasOfferNames
        };

        // Set initial visibility state
        return dataKeys.reduce((acc, key, index) => {
            let isVisible = false;

            if (categories.total && index === 0) {
                isVisible = true;
            } else if (categories.geo && geoName!.includes(key)) {
                isVisible = true;
            } else if (categories.affiliate && affiliateName!.includes(key)) {
                isVisible = true;
            } else if (categories.offer && offerName!.includes(key)) {
                isVisible = true;
            }

            return { ...acc, [replaceSpacesWithUnderscores(key)]: isVisible };
        }, {});
    });

    const processDataForChart = (data: LineChartData[], isCurrentDay = true) => {
        if (data.length === 0 || data[0].hour !== 0) {
            const zeroHour: LineChartData = { hour: 0 };
            if (data.length > 0) {
            Object.keys(data[0]).forEach(key => {
                if (key !== 'hour') zeroHour[key] = '0';
            });
            }
            data.unshift(zeroHour);
        }
        return data;
    };

    useEffect(() => {
        if (previousData !== null) {
            setProcessedPreviousData(processDataForChart(previousData, false));
        }
    }, [previousData, dataKeys]);

    useEffect(() => {
        if (currentData !== null) {
            setProcessedCurrentData(processDataForChart(currentData));
        }
    }, [currentData, dataKeys]);

    useEffect(() => {
        function findOverallLargestValue(datasets: [LineChartData[], LineChartData[]], keys: DataKeys) {
          let overallLargestValue = Number.MIN_SAFE_INTEGER;

          datasets.forEach(data => {
            data.forEach(item => {
              keys.forEach(key => {
                // @ts-ignore
                const value = parseInt((item)[replaceSpacesWithUnderscores(key)], 10);
                if (value > overallLargestValue) {
                  overallLargestValue = value;
                }
              });
            });
          });
      
          return overallLargestValue;
        }
      
        let maxValue = findOverallLargestValue([processedCurrentData, processedPreviousData], dataKeys);

        if ((maxValue &&  maxValue >= 0) || maxValue === 0) {
            maxValue += 10
            setIsData(true)
        };

        setMaxData(maxValue);
      }, [processedCurrentData, processedPreviousData, dataKeys]);
      

    function generateLines(data: LineChartData[], xAxisId: string, strokeDashArray: number, dataIndex: number) {
        return <>
            {dataKeys.map((line, index) => {
                return (
                    <Line
                        xAxisId={xAxisId}
                        key={(dataIndex === 0?index:10000-index)}
                        data={data}
                        type="linear"
                        dataKey={replaceSpacesWithUnderscores(line)}
                        strokeWidth={3}
                        stroke={lineColors[`color${index + 1}`]}
                        dot={false}
                        onMouseEnter={() => setActiveLine([replaceSpacesWithUnderscores(line), dataIndex])}
                        onMouseLeave={() => setActiveLine([null, null])}
                        activeDot={
                        {
                            r: 6,
                            onMouseOver: () => setActiveLine([replaceSpacesWithUnderscores(line), dataIndex]),
                            onMouseLeave: () => setActiveLine([null, null])
                        }}
                        strokeDasharray={strokeDashArray}
                        // @ts-ignore
                        hide={visibleLines[replaceSpacesWithUnderscores(line)] ? false : true}
                    />
                )
            })}
        </>;
    }

    function roundUpToNearestRoundNumber(maxValue = 0) {
        let targetValue = maxValue * 1.1;
    
        let magnitude = Math.pow(10, Math.floor(Math.log10(targetValue)));
        let multiplier = magnitude;
    
        if (magnitude >= 1000) {
            multiplier = magnitude / 2;
        } else if (magnitude >= 100) {
            multiplier = magnitude / 10;
        }
    
        return Math.ceil(targetValue / multiplier) * multiplier;
    }

    const formatXAxis = (tickItem: any) => {
        return isMobile ? tickItem : `${tickItem}h`;
    }

    const toggleLineVisibility = (lineKey: string) => {
        // @ts-ignore
        setVisibleLines(prevState => ({ ...prevState, [lineKey]: !prevState[lineKey] }));
    };

    const calculateMaxValues = (data: LineChartData[], dataKeys: DataKeys) => {
        const maxValues = dataKeys.reduce((acc, key) => {
            const maxValue = data.reduce((max, item) => {
                // @ts-ignore
                const value = parseInt(item[replaceSpacesWithUnderscores(key)], 10);
                return value > max ? value : max;
            }, Number.MIN_SAFE_INTEGER);
            acc[replaceSpacesWithUnderscores(key)] = maxValue;
            return acc;
        }, {} as Record<string, number>);
        return maxValues;
    };

    useEffect(() => {
        if (currentData) {
            setMaxForCurrentDate(calculateMaxValues(currentData, dataKeys));
        }
    }, [currentData, dataKeys]);

    useEffect(() => {
        if (previousData) {
            setMaxForPreviousDate(calculateMaxValues(previousData, dataKeys));
        }
    }, [previousData, dataKeys]);

    return (
        <div className={'flex flex-col gap-5 desktop-size:flex-row w-full'}>
            <div style={{borderColor: themedColors.gray.light_2}} className="border border-solid w-full rounded-[8px] dark:bg-odin-dark-blue-special">
                <div className={`w-full pt-2 pr-4 relative`} style={{height: isData && isDataFromBE ?  (isTablet && !isMobile) ? (263 + ((dataKeys.length / 3) * 30))  : isMobile ? (263 + ((dataKeys.length / 3) * 50)) : 'auto' : 'auto'}}>
                    {
                        isData && isDataFromBE ? (
                            <>
                                <ResponsiveContainer width="100%" height={(isTablet || isMobile) ? 263 : 600}>
                                    <RechartsLineChart margin={{right: 10}}>
                                        <CartesianGrid vertical={false}/>
                                        <XAxis dataKey={'hour'} xAxisId={'show'} interval={isBigScreen ? 1 : 0} allowDuplicatedCategory={false} className='text-xs ' tickFormatter={formatXAxis} tick={{ fill: theme === 'light' ? '#6B7280' : '#EBEFF7'}} 
                                        />
                                        <XAxis dataKey={'hour'} hide={true} xAxisId={'hide'} allowDuplicatedCategory={false} className='text-xs'  />
                                        <YAxis axisLine={false} padding={{ top: 50 }} domain={[0, roundUpToNearestRoundNumber(maxData)]} className='text-xs'  tick={{ fill: theme === 'light' ? '#6B7280' : '#EBEFF7'}}/>
                                        <Tooltip
                                            active={activeLine[0] !== null}
                                            content={({ payload }) => {
                                                
                                                if (payload && payload.length) {
                                                    const activeFilters = payload.filter(item => item.dataKey === activeLine[0]);
                                                    const currentDataPoint = payload.find(p => p.strokeDasharray === 0 && p.dataKey === activeLine[0]);
                                                    const previousDataPoint = payload.find(p => p.strokeDasharray === 8 && p.dataKey === activeLine[0]);

                                                    let activeData = activeLine[1] === 1 ? currentDataPoint : previousDataPoint;
                                                    let comparisonData = activeLine[1] === 1 ? previousDataPoint : currentDataPoint;


                                                    // if (!comparisonData) {
                                                    //     if (activeLine[1] === 1) {
                                                    //         comparisonData = { value: totalSalesForPreviousDate };
                                                    //     } else {
                                                    //         comparisonData = { value: totalSalesForCurrentDate };
                                                    //     }
                                                    // }
                                                    // if (!activeData) {
                                                    //     if (activeLine[1] === 1) {
                                                    //         activeData = { value: totalSalesForCurrentDate };
                                                    //     } else {
                                                    //         activeData = { value: totalSalesForPreviousDate };
                                                    //     }
                                                    // }

                                                    if (!comparisonData) {
                                                        if (activeLine[1] === 1) {
                                                            comparisonData = { value: maxForPreviousDate[activeLine[0]!] };
                                                        } else {
                                                            comparisonData = { value: maxForCurrentDate[activeLine[0]!] };
                                                        }
                                                    }
                                                    if (!activeData) {
                                                        if (activeLine[1] === 1) {
                                                            activeData = { value: maxForCurrentDate[activeLine[0]!] };
                                                        } else {
                                                            activeData = { value: maxForPreviousDate[activeLine[0]!] };
                                                        }
                                                    }

                                                    if (comparisonData?.value && Number(comparisonData?.value) < 0) comparisonData.value = 0;
                                                    if (activeData?.value && Number(activeData?.value) < 0) activeData.value = 0;

                                                    const activeSales = parseInt(activeData.value as string, 10);
                                                    const comparisonSales = parseInt(comparisonData.value as string, 10);
                                                    let percentageChange;
                                                    let percentageChangeSecondValue;
                                                    if (comparisonSales === 0) {
                                                        if (activeSales > 0) {
                                                            percentageChange = 100;
                                                            percentageChangeSecondValue = -100;
                                                        } else {
                                                            percentageChange = 0;
                                                            percentageChangeSecondValue = 0;
                                                        }
                                                    } else if (activeSales === 0) {
                                                        if (comparisonSales > 0) {
                                                            percentageChange = -100;
                                                            percentageChangeSecondValue = 100;
                                                        } else {
                                                            percentageChange = 0;
                                                            percentageChangeSecondValue = 0;
                                                        }

                                                    } else if (comparisonSales === 0 && activeSales === 0) {
                                                        percentageChange = 0;
                                                        percentageChangeSecondValue = 0;
                                                    } else {
                                                        percentageChange = ((activeSales - comparisonSales) / comparisonSales) * 100;
                                                        // calculate percentage change between two values on the contrary
                                                        percentageChangeSecondValue = ((comparisonSales - activeSales) / activeSales) * 100;

                                                    }

                                                    return (
                                                        <div className="custom-tooltip w-full " style={{color: theme === 'light' ? '#FFFFFF' : ''}}>
                                                        <div>
                                                            <span className='px-4 py-2'>{(dataKeys.includes(replaceUnderscoresWithSpaces(activeLine[0]!)) ? `${capitalizeSpecificWords(replaceUnderscoresWithSpaces(activeLine[0]!))}` : '')}</span>
                                                            </div>
                                                                <div className=" border-t-[0.5px] border-solid border-[#374151] dark:border-[#DDDFE3]"/>
                                                                    <div>

                                                                    <div className="flex justify-between px-4 py-1 min-w-[250px] w-full">
                                                                        <span className="flex items-center text-xs w-full justify-start gap-5">
                                                                                <div className='flex items-center gap-1 justify-between w-[125px]'>
                                                                                    <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M2 2L28 2" stroke={activeFilters[0].color} strokeWidth="3" strokeLinecap="round" strokeDasharray="8 8"/>
                                                                                    </svg>

                                                                                    <p>{(startDate as Dayjs).format('MMM D, YYYY ')}:</p>
                                                                                </div>
                                                                                
                                                                                <div className='flex gap-2 ml-auto  justify-between w-[90px]'>
                                                                                    <p>
                                                                                        {`
                                                                                            ${activeLine[1] === 1 ? comparisonSales.toLocaleString('en-US') : activeSales.toLocaleString('en-US')}
                                                                                        `}
                                                                                    </p>
                                                                                    <p className={
                                                                                        `
                                                                                            ${activeLine[1] !== 1 ? 
                                                                                            percentageChange > 0 ?  'text-odin-light-green-medium' : percentageChange < 0 ? ' text-odin-light-red-bright' : ' ' 
                                                                                            : percentageChange < 0 ?  'text-odin-light-green-medium' : percentageChange > 0 ? ' text-odin-light-red-bright' : ' '
                                                                                            } ml-auto
                                                                                        `
                                                                                    }>
                                                                                        {`${activeLine[1] !== 1 ? isFinite(percentageChange) ? percentageChange.toFixed(2) : 'N/A' : percentageChangeSecondValue.toFixed(2)}%`}
                                                                                    </p>
                                                                                </div>
                                                                               

                                                                        </span>
                                                                    </div>

                                                                    <div className="flex justify-between px-4 py-1 min-w-[250px] w-full">
                                                                        <span className="flex items-center text-xs w-full justify-start gap-5">
                                                                                <div className='flex items-center gap-1 justify-between w-[125px]'>
                                                                                    <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M2 2L28 2" stroke={activeFilters[0].color} strokeWidth="3" strokeLinecap="round"/>
                                                                                    </svg>

                                                                                    <p>{(endDate as Dayjs).format('MMM D, YYYY ')}:</p>
                                                                                </div>
                                                                                
                                                                                <div className='flex gap-2 ml-auto  justify-between w-[90px]'>
                                                                                    <p>
                                                                                        {`
                                                                                            ${activeLine[1] === 1 ? activeSales.toLocaleString('en-US') :  comparisonSales.toLocaleString('en-US')}
                                                                                        `}
                                                                                    </p>

                                                                                    <p className={
                                                                                        `
                                                                                        ${activeLine[1] !== 1 ? 
                                                                                        percentageChangeSecondValue > 0 ? 'text-odin-light-green-medium' : percentageChangeSecondValue < 0 ? ' text-odin-light-red-bright' : theme === 'light' ? '#FFFFFF' : ''
                                                                                            : percentageChangeSecondValue < 0 ? 'text-odin-light-green-medium' : percentageChangeSecondValue > 0 ? ' text-odin-light-red-bright' : theme === 'light' ? '#FFFFFF' : ''
                                                                                        } ml-auto
                                                                                        `
                                                                                    }>
                                                                                        {`${activeLine[1] === 1 ? isFinite(percentageChangeSecondValue) ? percentageChange.toFixed(2)  : 'N/A' : percentageChangeSecondValue.toFixed(2)}%`}
                                                                                    </p>
                                                                                </div>
                                                                                

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            }}
                                            wrapperStyle={{
                                                backgroundColor: theme === 'light' ? '#242546' : '#EBEFF7',
                                                opacity: '1',
                                                padding: '0px',
                                                borderRadius: '5px',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderColor: `${themedColors.gray.light}`,
                                            }}
                                        />
                                        {generateLines(processedPreviousData, 'show', 8, 0)}
                                        {generateLines(processedCurrentData, 'hide', 0, 1)}
                                    </RechartsLineChart>
                                </ResponsiveContainer>
                            </>
                        ) : (
                            <div className='flex justify-center items-center w-full mt-14 mb-14 p-3'>
                                <p className='text-sm text-odin-light-gray-bluish'>
                                <div className='flex flex-col gap-3 items-center justify-center'>
                                    <NoDataFoundIcon />
                                    <h2 className='text-2xl font-semibold text-center'>No data found</h2>
                                    <p className='text-center'>{errorMessage ?? "Filters are incorrect or data is empty"}</p>
                                 </div>
                                </p>
                            </div>
                        )
                    }

                    {
                        isData && isDataFromBE && (
                            <div className='absolute top-2 tablet-size:top-4 right-6'>
                                <LineGraphTooltip startDate={startDate as Dayjs} endDate={endDate as Dayjs} />
                            </div>
                        )
                    }

                    {
                        isData && isDataFromBE && tooltipLabel && (
                            <div className='hidden md:block absolute top-2 tablet-size:top-4 left-7' style={{color: themedColors.content}}>
                                {tooltipLabel}
                            </div>
                        )
                    }
                    
                    
                </div>
            </div>
            {
                isData && isDataFromBE && (
                    <div className="flex desktop-size:w-[25%]">
                        <LineChartSideBar 
                            visibleLines={visibleLines} 
                            toggleLineVisibility={toggleLineVisibility} 
                            geoName={geoName} 
                            affiliateName={affiliateName}
                            offerName={offerName}
                        />
                    </div>
                )
            }
            
        </div>
    );
};

export default LineChart;