import React, {useContext, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../../assets/swiss_logo_new.png";
import "./login.css";
import SessionManager from "../../../odinForgeService/SessionManager";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import Input from "../../UI/Input/Input";
import DefaultButton from "../../UI/buttons/DefaultButton";
import Logo from "../../../assets/icons/Logo";

interface LoginViewProps {
    username: string,
    password: string,
    showPassword: boolean,
    handleInputChange1: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleInputChange2: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleTogglePassword: () => void
    onSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void
    login: boolean,
    rememberMe: boolean,
    handleRememberMeChange: () => void

}

export function LoginView({
  username,
  password,
  showPassword,
  handleInputChange1,
  handleInputChange2,
  handleTogglePassword,
  onSubmitHandler,
  login,
  rememberMe,
  handleRememberMeChange,
}: LoginViewProps) {
  const navigate = useNavigate();
  const sessionManager = SessionManager.instance();
  const { themedColors } = useContext(ThemeContext)!;

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      return () => {
        navigate("/");
      };
    };

    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

        if (!sessionManager.getToken()) {
            navigate("/");
        }

        window.history.pushState(null, "", window.location.href);
        window.onpopstate = handleBackButton;
        window.addEventListener("pageshow", handlePageShow);

        return () => {
            window.onpopstate = null;
        };
    }, []);

    return (
        <div
            className={
                "h-auto mt-24 tablet-size:h-full tablet-size:min-h-screen tablet-size:mt-0 flex justify-center tablet-size:justify-normal xl:gap-[14rem] tablet-size:gap-[7rem] tall:h-auto dark:bg-odin-dark-blue-dark"
            }
        >
            <div className="hidden tablet-size:flex items-center left-panel tablet-size h-auto tall:pt-24 tall:pb-24 relative !z-10">
                <img src={logo} className="p-12" alt=""/>
            </div>
            <div className="flex flex-row h-full tablet-size:mt-auto tablet-size:mb-auto">
                <div
                    className="flex items-center justify-center h-full w-full p-4 s:w-[300px] mobile-size:w-[350px] tablet-size:p-0 tablet-size:w-[480px] tall:pt-24 tall:pb-24 ">
                    <div className="relative w-full max-w-[445px]">
                        <Logo viewBox="400 250 364 364" />
                        <div>
                            <p style={{color: themedColors.content}}  className="sub-head">Welcome back!</p>
                            <p style={{color: themedColors.content}} className="sub-description">
                                Enter your credentials to access your account
                            </p>
                        </div>
                        
                        <form onSubmit={onSubmitHandler}>
                            <div>
                                <Input 
                                    value={username} 
                                    onChange={handleInputChange1} 
                                    type='text' 
                                    label={'Username'} 
                                    name={'username'}
                                    placeholder={'Enter your username'}
                                    isRequired={true}
                                />
                            </div>

                            <div className="flex flex-col mt-4">
                                <Input 
                                    value={password} 
                                    onChange={handleInputChange2} 
                                    type={showPassword ? "text" : "password"} 
                                    label={'Password'} 
                                    name={'Password'}
                                    placeholder={'Enter your password'}
                                    showPassword={showPassword}
                                    handleTogglePassword={handleTogglePassword}
                                    isPassword={true}
                                    isRequired={true}
                                />
                            </div>

                            <div className="flex flex-row mt-2 justify-between items-center">
                                <div className="flex items-center">
                                    <input
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        type="checkbox"
                                        className="mr-3 checkbox cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-odin-dark-gray-extra_dark dark:ring-offset-gray-800 focus:ring-2 dark:bg-odin-dark-blue-dark dark:border-odin-dark-gray-extra_dark"
                                        id="rememberMeCheckbox"
                                    />
                                    <label
                                        htmlFor="rememberMeCheckbox"
                                        className="text-sm cursor-pointer"
                                        style={{color: themedColors.content}}
                                    >
                                        Remember me
                                    </label>
                                </div>
                                <div>
                                    <nav>
                                        <Link
                                            to={"/forgot-password"}
                                            className="relative top-[-2px] text-sm text-[#0079FF]"
                                        >
                                            Forgot Password?
                                        </Link>
                                    </nav>
                                </div>
                            </div>

                            <div className="flex pt-8">
                                <DefaultButton disabled={login} type='submit' label='Log in' className="w-full h-11" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
