import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const MiskStats = () => {
    const { theme } = useContext(ThemeContext)!;

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7692_336498)">
        <path d="M14.375 11.1682V6.83483C14.3748 6.64485 14.3247 6.45827 14.2296 6.29379C14.1345 6.12931 13.9979 5.99273 13.8333 5.89774L10.0417 3.73108C9.87698 3.63599 9.69016 3.58594 9.5 3.58594C9.30984 3.58594 9.12302 3.63599 8.95833 3.73108L5.16667 5.89774C5.00214 5.99273 4.86549 6.12931 4.77042 6.29379C4.67534 6.45827 4.62519 6.64485 4.625 6.83483V11.1682C4.62519 11.3581 4.67534 11.5447 4.77042 11.7092C4.86549 11.8737 5.00214 12.0103 5.16667 12.1052L8.95833 14.2719C9.12302 14.367 9.30984 14.417 9.5 14.417C9.69016 14.417 9.87698 14.367 10.0417 14.2719L13.8333 12.1052C13.9979 12.0103 14.1345 11.8737 14.2296 11.7092C14.3247 11.5447 14.3748 11.3581 14.375 11.1682Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <path d="M26.2917 17.125H18.7083C18.11 17.125 17.625 17.61 17.625 18.2083V25.7917C17.625 26.39 18.11 26.875 18.7083 26.875H26.2917C26.89 26.875 27.375 26.39 27.375 25.7917V18.2083C27.375 17.61 26.89 17.125 26.2917 17.125Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <g clipPath="url(#clip1_7692_336498)">
        <path d="M8.57376 17.6973L3.98584 25.3564C3.89125 25.5202 3.8412 25.706 3.84067 25.8951C3.84014 26.0843 3.88915 26.2703 3.98283 26.4346C4.0765 26.599 4.21157 26.7359 4.3746 26.8319C4.53764 26.9278 4.72294 26.9794 4.91209 26.9814H14.0879C14.2771 26.9794 14.4624 26.9278 14.6254 26.8319C14.7885 26.7359 14.9235 26.599 15.0172 26.4346C15.1109 26.2703 15.1599 26.0843 15.1594 25.8951C15.1588 25.706 15.1088 25.5202 15.0142 25.3564L10.4263 17.6973C10.3297 17.5381 10.1937 17.4065 10.0315 17.3151C9.86925 17.2238 9.6862 17.1758 9.50001 17.1758C9.31382 17.1758 9.13077 17.2238 8.96853 17.3151C8.80629 17.4065 8.67032 17.5381 8.57376 17.6973Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g clipPath="url(#clip2_7692_336498)">
        <path d="M22.5 3.58203L24.1737 6.97286L27.9167 7.51995L25.2083 10.1579L25.8475 13.8845L22.5 12.1241L19.1525 13.8845L19.7917 10.1579L17.0833 7.51995L20.8262 6.97286L22.5 3.58203Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7692_336498">
        <rect width="13" height="13" fill="white" transform="translate(3 2.5)"/>
        </clipPath>
        <clipPath id="clip1_7692_336498">
        <rect width="13" height="13" fill="white" transform="translate(3 16.5)"/>
        </clipPath>
        <clipPath id="clip2_7692_336498">
        <rect width="13" height="13" fill="white" transform="translate(16 2.5)"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default MiskStats