import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const MiskStat = () => {
    const { theme } = useContext(ThemeContext)!;
    
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 12.8329V6.16626C16.4997 5.87399 16.4225 5.58693 16.2763 5.33389C16.13 5.08086 15.9198 4.87073 15.6667 4.72459L9.83333 1.39126C9.57997 1.24498 9.29256 1.16797 9 1.16797C8.70744 1.16797 8.42003 1.24498 8.16667 1.39126L2.33333 4.72459C2.08022 4.87073 1.86998 5.08086 1.72372 5.33389C1.57745 5.58693 1.5003 5.87399 1.5 6.16626V12.8329C1.5003 13.1252 1.57745 13.4122 1.72372 13.6653C1.86998 13.9183 2.08022 14.1285 2.33333 14.2746L8.16667 17.6079C8.42003 17.7542 8.70744 17.8312 9 17.8312C9.29256 17.8312 9.57997 17.7542 9.83333 17.6079L15.6667 14.2746C15.9198 14.1285 16.13 13.9183 16.2763 13.6653C16.4225 13.4122 16.4997 13.1252 16.5 12.8329Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MiskStat