import React from "react";
import DashboardMetricCard from "./DashboardMetricCard";
import ProfileIcon from "../../../../../assets/icons/ProfileIcon";
import BasketIcon from "../../../../../assets/icons/BasketIcon";
import DashboardTable from "./DashboardTable";


interface DashboardOverviewReportProps {
  data: any;
}

enum titlesEnum {
  creditCardOverview = "Credit Card Overview",
  creditCardDecline = "Credit Card Decline",
  productBreakdown = "Product Breakdown",
  miscStatsOverview = "Misc Stats Overview",
};

const DashboardOverviewReport = ({ data }: DashboardOverviewReportProps) => {
  return (
    <div className="pt-0 tablet-size:pt-6 w-full relative max-w-full flex flex-col gap-6">
      <div className="flex gap-6 flex-col tablet-size:flex-row">
        <DashboardMetricCard
          icon={<ProfileIcon />}
          amount={
            Number(data?.approval_rate?.amount) ? `${parseFloat(data?.approval_rate?.amount.toString()).toFixed(2)}%` : "---"
          }
          bgColor="bg-backgroundColor-light-green dark:bg-[#293342]"
          label={'Approval rate'}
          isDescription={true}
          data={data?.approval_rate}
        />
        <DashboardMetricCard
          icon={<BasketIcon />}
          amount={
            Number(data?.average_order_value?.amount)
              ? `${Number(data?.average_order_value?.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              : "---"
          }
          bgColor="bg-backgroundColor-light-yellow dark:bg-[#303C50]"
          label={'Average Order Value'}
          isDescription={false}
          currency={data?.average_order_value?.currency}
        />
      </div>
      <div className="flex gap-6 flex-col tablet-size:flex-row items-baseline">
        <div className="w-full flex gap-6 flex-wrap">
          <DashboardTable
            data={
              data.credit_card_overview
                && data.credit_card_overview
            }
            title={titlesEnum.creditCardOverview}
            tableType={"card"}
            height="220px"
          />

          <DashboardTable
            data={
              data.credit_card_decline
                && data.credit_card_decline
            }
            title={titlesEnum.creditCardDecline}
            tableType={"card"}
            height="431px"
          />
        </div>
        <div className="w-full  flex gap-6 flex-wrap">
          <DashboardTable
            data={
              data.product_breakdown
                && data.product_breakdown
            }
            title={titlesEnum.productBreakdown}
            tableType={"product"}
            height="220px"
          />

          <DashboardTable
            data={
              data.misc_stats_overview
                && data.misc_stats_overview
            }
            title={titlesEnum.miscStatsOverview}
            tableType={"misk"}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewReport;
