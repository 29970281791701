import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
  'Merchant Account': 'mid_account',
  'Merchant Name': 'mid_name',
  'BIN': 'card_bin',
  'Affiliate': 'affiliate_name',
  'Card Type': 'card_brand',
};

export const names = [
    'Merchant Account',
    'Merchant Name',
    'BIN',
    'Affiliate',
    'Card Type',
];

export const tableHeadingNames = {
    attempts: 'Attempts',
    successful: 'Success',
    failures: 'Failures',
    success_rate: 'Success Rate',
}

export const options = [
  { value: 'all', label: 'All' },
  { value: 'main_offer', label: 'Main Offer' },
  { value: 'upsell', label: 'Upsell' },
];
// card_processing_txs_type = 'all' | 'main_offer' | 'upsell'

export const optionsAttemptTxsType = [
  { value: 'all', label: 'All' },
  { value: 'initial', label: 'Initial' },
  { value: 'reproc', label: 'Reproc' },
  { value: 'rebill', label: 'Rebill' },
];
// card_processing_attempt_txs_type = 'all' | 'initial' | 'reproc' | 'rebill'