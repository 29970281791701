import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import { BinRoutingReportData } from '../types/bin-routing-performace-report.types';

dayjs.extend(utc);
dayjs.extend(timezone);

const columnHelper = createColumnHelper<BinRoutingReportData>()

export const tableColumns = [
    columnHelper.accessor('bin_routing_name', {
        header: 'Routing Name',
        cell: ({ getValue }) => capitalizeSpecificWords(getValue()),
    }),
    columnHelper.accessor('attempts', {
        header: 'Attempts',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('success', {
        header: 'Success',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.accessor('decline', {
        header: 'Decline',
        cell: ({ getValue }) => {
            const value = getValue();
            return value !== null && value !== undefined
              ? Number(value).toLocaleString()
              : '---';
        },
        meta: {
            isAlignTextRight: true,
        }
    }),
    columnHelper.display({
        id: 'successRate',
        header: 'Success Rate',
        cell: ({ row }) => {
          const { success, attempts } = row.original;
          const rate = ((success / attempts) * 100).toFixed(2) + '%';
          return rate;
        },
        meta: {
          isAlignTextRight: true,
        },
    }),
];