import React, {useContext, useEffect, useState} from "react";
import logo from "../../../assets/swiss_logo_new.png";
import "./login.css";
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import {useLoadingContext} from "../../UI/loading/LoadingContext";
import LoadingPopup from "../../UI/loading/LoadingPopup";
import Utils from "../../../odinForgeService/Utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../UI/buttons/DefaultButton";
import Input from "../../UI/Input/Input";
import SuccessResetPasswordPopup from "../../UI/SuccessResetPasswordPopup/SuccessResetPasswordPopup";
import Logo from "../../../assets/icons/Logo";

type PasswordField = 'newPassword' | 'confirmNewPassword';

export function ResetPassword() {
    const { themedColors } = useContext(ThemeContext)!;
    const [email, setEmail] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [token, setToken] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const loadingContext = useLoadingContext();
    const [searchParams] = useSearchParams()

    const [showPassword, setShowPassword] = useState({
        newPassword: false,
        confirmNewPassword: false,
    });

    const [success, setSuccess] = useState<boolean>(false);


    const navigate = useNavigate()

    const validatePassword = (password: string) => {
        const criteria = [
            { test: password.length >= 8, message: "Minimum length of 8 characters." },
            { test: /[A-Z]/.test(password), message: "At least one uppercase letter." },
            { test: /[a-z]/.test(password), message: "At least one lowercase letter." },
            { test: /[0-9]/.test(password), message: "At least one number." },
            { test: /[!@#$%^&*(),.?":{}|<>]/.test(password), message: "At least one special character." },
        ];

        const unmetCriteria = criteria.filter(criterion => !criterion.test).map(criterion => criterion.message);
        return unmetCriteria;
    };

    useEffect(() => {
        const emailParam = searchParams.get("email")
        const tokenParam = searchParams.get("token")
        if (emailParam){
            setEmail(emailParam)
        }
        if (tokenParam){
            setToken(tokenParam)
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        loadingContext.setLoading(true)
        const unmetCriteria = validatePassword(password);
        if (unmetCriteria.length > 0) {
            Utils.instance().onFailure(`Password does not meet the required criteria: ${unmetCriteria.join(", ")}`);
            loadingContext.setLoading(false);
            return;
        }

        if (password === confirmPassword){
            await OdinForgeService.instance().resetPassword(email, password, confirmPassword, token )
                .then((result)=> {
                    Utils.instance().onSuccess(result.data.message)
                    setSuccess(true)
                })
                .catch((error) => {
                    const errorData = error.response.data
                    if (errorData.errors){
                        const errors = errorData.errors
                        Object.keys(errors).forEach((field) => {
                            errors[field].forEach((message: string) => {
                                Utils.instance().onFailure(message);
                            });
                        });
                    }else {
                        const message = error.response.data.message
                        Utils.instance().onFailure(message)
                    }
                })
        }else {
            Utils.instance().onFailure("The Passwords do not match")
        }
        loadingContext.setLoading(false)
    }

    const handleTogglePassword = (field: PasswordField) => {
        setShowPassword((prevState) => ({
          ...prevState,
          [field]: !prevState[field],
        }));
      };

    return (
        <div
            className={
                "h-auto mt-24 tablet-size:h-full tablet-size:min-h-screen tablet-size:mt-0 flex justify-center tablet-size:justify-normal xl:gap-[14rem] tablet-size:gap-[7rem] tall:h-auto dark:bg-odin-dark-blue-dark"
            }
        >
            {loadingContext.loading && <LoadingPopup loadingText='Loading....'/>}
            <div className="hidden tablet-size:flex items-center left-panel tablet-size h-auto tall:pt-24 tall:pb-24 !z-10">
                <img src={logo} className="p-12" alt=""/>
            </div>
            <div className="flex flex-row h-full tablet-size:mt-auto tablet-size:mb-auto">
                <div
                    className="flex items-center justify-center h-full w-full p-4 s:w-[300px] mobile-size:w-[350px] tablet-size:p-0 tablet-size:w-[480px] tall:pt-24 tall:pb-24">
                    <div className="relative w-full max-w-[445px]">
                        <Logo viewBox="400 250 364 364" />
                        <p style={{color: themedColors.content}} className={"sub-head mb-8"}>Set New Password</p>
                        <form onSubmit={onSubmit}>
                            <div>
                                <div>
                                    <Input
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        name="New password"
                                        label="New password"
                                        placeholder={"New password"}
                                        value={password}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setPassword(value)
                                            const isEmpty = value === '' || confirmPassword === ''
                                            setDisableSubmit(isEmpty)
                                        }}
                                        showPassword={showPassword.newPassword}
                                        handleTogglePassword={() => handleTogglePassword('newPassword')}
                                        isPassword={true}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="flex flex-col mt-4">
                                    <Input
                                        type={showPassword.confirmNewPassword ? 'text' : 'password'}
                                        name={'Confirm new password'}
                                        label={'Confirm new password'}
                                        placeholder={"Confirm new password"}
                                        value={confirmPassword}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            setConfirmPassword(value)
                                            const isEmpty = value === '' || password === ''
                                            setDisableSubmit(isEmpty)
                                        }}
                                        showPassword={showPassword.confirmNewPassword}
                                        handleTogglePassword={() =>
                                            handleTogglePassword('confirmNewPassword')
                                        }
                                        isPassword={true}
                                        isRequired={true}
                                    />
                                </div>
                            </div>

                            <div className="flex pt-8">
                                <DefaultButton disabled={disableSubmit} type='submit' label='Reset Password' className="w-full h-11" />
                            </div>

                            {success && (
                                <SuccessResetPasswordPopup
                                    successText = "Password reset successfully"
                                    onClick={() => navigate('/')}
                                    onClose={() => setSuccess(false)}
                                    buttonMessage="Back to Login"
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}