import React, {FC, useContext} from "react";
import CopyIcon from "../../../assets/icons/Copy";
import FreezeIcon from "../../../assets/icons/Freeze";
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import Utils from "../../../odinForgeService/Utils";
import {useLoadingContext} from "../loading/LoadingContext";
import RemovePopup from "../removePopup/RemovePopup";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import CrmToggleButtonGroup from "../crmType/CustomToggleButtonGroups";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../buttons/DefaultButton";
import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
import { LineChartClonedData } from "../../pages/reports/LineChart/types/line-chart.types";
import { Tooltip } from "@mui/material";

dayjs.extend(utc);

interface PropsFilterLineChart {
    reportName: string;
    copyButton: boolean;
    setCopyButton: (value: boolean) => void;
    disableButton: boolean;
    buttonBackgroundColor: string;
    setButtonBackgroundColor: (value: string) => void;
    iconFillColor: string;
    geoName: string[];
    affiliateName: string[];
    offerNames: string[];
    setIconFillColor: (value: string) => void;
    setDisableButton: (value: boolean) => void;
    passData: (data: LineChartClonedData) => void;
    clonedReportId: number;
    passId: (id: string | number | null | undefined) => void;
    setDeletePopup: (value: boolean) => void;
    deletePopup: boolean;
    showDelete: boolean;
    dateRange: number
    crmType: string;
    setCrmType: (value: string) => void;
    clonedData: LineChartClonedData;
    enableFiltersApplyButton: boolean;
    isToggle?: boolean;
    startDate?: string | Dayjs;
    endDate?: string | Dayjs;
    setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
    refreshKey?: number
}

const FilterButtonsLineChart:
    FC<PropsFilterLineChart> = ({
                                    copyButton,
                                    setCopyButton,
                                    reportName,
                                    disableButton,
                                    buttonBackgroundColor,
                                    setButtonBackgroundColor,
                                    iconFillColor,
                                    clonedData,
                                    geoName,
                                    affiliateName,
                                    offerNames,
                                    setIconFillColor,
                                    setDisableButton,
                                    passData,
                                    clonedReportId,
                                    passId,
                                    setDeletePopup,
                                    deletePopup,
                                    showDelete,
                                    dateRange,
                                    crmType,
                                    setCrmType,
                                    enableFiltersApplyButton,
                                    isToggle = true,
                                    startDate,
                                    endDate,
                                    setRefreshKey,
                                    refreshKey
                                }) =>{

        const loadingContext = useLoadingContext();
        const { theme } = useContext(ThemeContext)!;

        const setIconColorOnSuccess = (response: object) => {
            if (response) {
                setIconFillColor('#fff');
            }
        };
        const saveFilter = async () => {
            try {
                loadingContext.setLoading(true);
                let update = false
                if (!!clonedData.id){
                    update = true
                }
                    const response = await OdinForgeService.instance().postLineChartConfig(reportName,geoName, dateRange, affiliateName,offerNames,update,clonedReportId, startDate, endDate);
                    setIconFillColor(response ? '#0079FF' : '');
                    setButtonBackgroundColor(response ? '#0079FF' : '');
                    setIconColorOnSuccess(response);
                    setDisableButton(true);
                    setCopyButton(false);
                    setRefreshKey && setRefreshKey(prevKey => prevKey + 1);
                    Utils.instance().onSuccess('Changes are saved')
                // }
            } catch (error) {
                Utils.instance().onFailure('An error occurred')
            } finally {
                setDisableButton(true);
                loadingContext.setLoading(false);
            }
        };



        const cloneLineChart = async () => {
            try {
                loadingContext.setLoading(true);
                const response = await OdinForgeService.instance().postLineChartConfig(reportName,geoName, dateRange, affiliateName,offerNames,false, clonedReportId, startDate, endDate);
                const formattedStartDate = dayjs(startDate).set('second', 0).format('YYYY-MM-DD');
                const formattedEndDate = dayjs(endDate).set('second', 0).format('YYYY-MM-DD');

                const dataToPass = {
                    geo_name: geoName,
                    date_range:dateRange,
                    affiliate_name: affiliateName,
                    offer_name: offerNames,
                    type:'cloned',
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    id: response.data.data.id,
                }
                passData(dataToPass)
                Utils.instance().onSuccess('Chart Copied')
            }catch (error) {
                Utils.instance().onFailure('An error occurred')
            }finally {
                loadingContext.setLoading(false);
            }
        }

        const deleteCopyReport = async () => {
            try {
                await OdinForgeService.instance().deleteLineChartConfig(clonedReportId);
                loadingContext.setLoading(true);
                passId(clonedReportId)
                Utils.instance().onSuccess('Removed Report');
                setDeletePopup(false);
                loadingContext.setLoading(false);
            }catch (er){
                Utils.instance().onFailure('An error occurred')
            }
        }

        function getDeleteButton() {
            return (
                <Tooltip
                    title={"Delete board"}
                    placement={"bottom"}
                    classes={{
                        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                    }}
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={1000}
                >
                    <span>
                        <DefaultButton
                            onClick={()=>setDeletePopup(true)}
                            type="button" variant="outlined"
                            color='error'
                            className={`flex border-2 w-16 h-10 mr-3 rounded-3xl justify-center delete items-center`}
                            label={
                                <DeleteIcon fill={copyButton ? '#677C9F' : '#0079FF'} />
                            }
                        />
                        </span>
                </Tooltip>
            )
        }

    return(
        <div className={'relative flex items-end flex-row w-full justify-between tablet-size:w-auto tablet-size:justify-normal gap-4 tablet-size:gap-7 flex-wrap'}>
            {deletePopup && <RemovePopup onClose={() => setDeletePopup(false)} onNoClick={() => setDeletePopup(false)}
                                         removeText="Confirm you want to remove this report?"
                                         onYesClick={deleteCopyReport}/>}

            {
                isToggle && (
                    <CrmToggleButtonGroup crmType={crmType} setCrmType={setCrmType} />
                )
            }

            

            <div className='flex flex-wrap gap-3'>
                <Tooltip
                    title={"Duplicate board"}
                    placement={"bottom"}
                    classes={{
                        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                    }}
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={1000}
                >
                    <span>
                        <DefaultButton disabled={copyButton} onClick={cloneLineChart} type="button" variant="outlined" className="h-10" label={
                            <CopyIcon fill={copyButton ? '#677C9F' : '#0079FF'} />
                        } />
                    </span>
                </Tooltip>

                <Tooltip
                    title={!disableButton ? "Save filter settings" : "Your filters are already saved"}
                    placement={"bottom"}
                    classes={{
                        tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                        arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                    }}
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={1000}
                >
                    <span>
                        <DefaultButton disabled={disableButton || !enableFiltersApplyButton} onClick={saveFilter} type="button" variant="outlined" className="h-10" label={
                            <FreezeIcon fill={`${(disableButton || !enableFiltersApplyButton) ? '#677C9F' : '#0079FF' }`}/>
                        } />
                    </span>
                </Tooltip>
                
                
                {showDelete?getDeleteButton():null}
            </div>

        </div>
    )
    }

export default FilterButtonsLineChart;