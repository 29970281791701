
import React, { useContext } from "react";
import { StatsLegendComponent } from "./StatsLegendComponent";
import DonutChart from "../../../../UI/charts/DonutChart";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import { CancellationReportData } from "../types/cancellation-report.types";

interface TotalSectionComponentProps {
  data: CancellationReportData | [];
}

function transformData(input: CancellationReportData) {
  const keyMap = {
    "cancelled_due_to_refund": "Cancelled due to refund",
    "blacklist": "Blacklisted",
    "cannot_afford": "Cannot afford",
    "did_not_order": "Did not order",
    "did_not_receive": "Did not receive",
    "does_not_like": "Does not like",
    "possible_dispute": "Possible dispute",
    "not_as_expected": "Not as expected",
    "chargeback": "Customer chargeback",
    "doctor_advised": "Doctor advised",
    "not_aware_of_trial_terms": "Was not aware of terms",
    "product_not_working": "Product not working",
    "product_returned": "Product returned",
    "too_much_product": "Too much product",
    "ethoca_alerts": "Ethoca alert"
  };

  let unknownTotal = 0;

  const transformedData = Object.entries(input).map(([key, value]) => {
    if (key in keyMap) {
      const name = keyMap[key as keyof typeof keyMap];
      const percent = typeof value === "string" || typeof value === "number"
        ? Math.round(parseFloat(String(value)) * 100) / 100
        : 0.00;
      return { name, percent };
    } else {
      if (key === "num_of_cancelled_orders") return null;
      unknownTotal += typeof value === "string" || typeof value === "number"
        ? parseFloat(String(value))
        : 0;
      return null;
    }
  }).filter((item): item is { name: string; percent: number } => item !== null);

  if (unknownTotal > 0) {
    transformedData.push({
      name: "Unknown cancellation reason",
      percent: Math.round(unknownTotal * 100) / 100
    });
  }

  return transformedData;
}


export function TotalSectionComponent({ data }: TotalSectionComponentProps) {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <div>
      <div className={"text-2xl font-bold	"} style={{color: themedColors.content}}>Cancellation Reasons (Orders)</div>

      <div className="flex flex-row gap-10 items-start pt-4 h-full flex-wrap">
            <div className={"flex flex-1 justify-center w-full [&_g]:stroke-none min-w-[250px] self-center"}>
              {/* @ts-ignore */}
              <DonutChart data={transformData(data.total[0].stats)} variant='pie' className='max-w-64 h-64' />
            </div>
            <div className={"flex-1 min-w-[250px]"}>
              <div className="flex flex-col gap-4">
                <div style={{
                  color: themedColors.content,
                  borderBottom: `1px solid ${themedColors.border}`,
                  paddingBottom: '10px',
                }}>
                  {/* @ts-ignore */}
                  Canceled orders: {(data.total[0].stats.num_of_cancelled_orders)?.toLocaleString('en-US') ?? 0}
                </div>
                <StatsLegendComponent
                  // @ts-ignore
                  data={transformData(data.total[0].stats)}
                  showPercent={true}
                />
              </div>
              
            </div>
        </div>
    </div>
    
  );
}
