export const timeZoneAbbreviations: { [key: string]: string } = {
    // Africa Time Zones
    'GMT': 'Etc/GMT', // Greenwich Mean Time
    'EAT': 'Africa/Nairobi', // East Africa Time
    'CAT': 'Africa/Harare', // Central Africa Time
    'WAT': 'Africa/Lagos', // West Africa Time
    'SAST': 'Africa/Johannesburg', // South Africa Standard Time
    'EET': 'Europe/Cairo', // Eastern European Time (used in Egypt)
  
    // America Time Zones
    'NST': 'America/St_Johns', // Newfoundland Standard Time
    'NDT': 'America/St_Johns', // Newfoundland Daylight Time
    'AST': 'America/Halifax', // Atlantic Standard Time
    'ADT': 'America/Halifax', // Atlantic Daylight Time
    'EST': 'America/New_York', // Eastern Standard Time
    'EDT': 'America/New_York', // Eastern Daylight Time
    'CST': 'America/Chicago', // Central Standard Time
    'CDT': 'America/Chicago', // Central Daylight Time
    'MST': 'America/Denver', // Mountain Standard Time
    'MDT': 'America/Denver', // Mountain Daylight Time
    'PST': 'America/Los_Angeles', // Pacific Standard Time
    'PDT': 'America/Los_Angeles', // Pacific Daylight Time
    'AKST': 'America/Anchorage', // Alaska Standard Time
    'AKDT': 'America/Anchorage', // Alaska Daylight Time
    'HST': 'Pacific/Honolulu', // Hawaii Standard Time
    'SST': 'Pacific/Pago_Pago', // Samoa Standard Time
    'CHST': 'Pacific/Guam', // Chamorro Standard Time
  
    // Antarctica Time Zones
    'NZST': 'Pacific/Auckland', // New Zealand Standard Time
    'NZDT': 'Pacific/Auckland', // New Zealand Daylight Time
    'AWST': 'Australia/Perth', // Australian Western Standard Time
    'AEDT': 'Australia/Sydney', // Australian Eastern Daylight Time
    'AEST': 'Australia/Brisbane', // Australian Eastern Standard Time
    'ACST': 'Australia/Adelaide', // Australian Central Standard Time
    'ACDT': 'Australia/Adelaide', // Australian Central Daylight Time
  
    // Europe Time Zones
    'WET': 'Europe/Lisbon', // Western European Time
    'WEST': 'Europe/Lisbon', // Western European Summer Time
    'CET': 'Europe/Berlin', // Central European Time
    'CEST': 'Europe/Berlin', // Central European Summer Time
    'EEST': 'Europe/Athens', // Eastern European Summer Time
    'MSK': 'Europe/Moscow', // Moscow Standard Time
  
    // Asia Time Zones
    'IST': 'Asia/Kolkata', // India Standard Time
    'PKT': 'Asia/Karachi', // Pakistan Standard Time
    'BST': 'Asia/Dhaka', // Bangladesh Standard Time
    'WIB': 'Asia/Jakarta', // Western Indonesia Time
    'WITA': 'Asia/Makassar', // Central Indonesia Time
    'WIT': 'Asia/Jayapura', // Eastern Indonesia Time
    'HKT': 'Asia/Hong_Kong', // Hong Kong Time
    'SGT': 'Asia/Singapore', // Singapore Time
    'KST': 'Asia/Seoul', // Korea Standard Time
    'JST': 'Asia/Tokyo', // Japan Standard Time
  
    // Middle East
    'IRST': 'Asia/Tehran', // Iran Standard Time
    'IRDT': 'Asia/Tehran', // Iran Daylight Time
  
    // Pacific Time Zones
    'CHAST': 'Pacific/Chatham', // Chatham Standard Time
    'CHADT': 'Pacific/Chatham', // Chatham Daylight Time
  
    // UTC
    'UTC': 'Etc/UTC', // Coordinated Universal Time
  
    // Other
    'ART': 'Africa/Cairo', // Eastern European Time (used in Egypt)
};
  