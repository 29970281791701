import React, { useContext } from 'react'
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';

type StatusLevel = 0 | 1;

interface StatusProps {
    level: StatusLevel;
}

const VerticalProductStatus: React.FC<StatusProps> = ({ level }) => {
    const { theme } = useContext(ThemeContext)!;

    let backgroundColor;
    let textColor;
    let label;
    let width;

    if (level === 0) {   
        backgroundColor = theme === 'light' ? 'bg-[#FEF2F2]' : 'bg-[#453838]';
        textColor = theme === 'light' ? 'text-[#E74C3C]' : 'text-[#FDB5AE]';
        label = 'No';
        width = 'w-[56px]'
    } else if (level === 1) {
        backgroundColor = theme === 'light' ? 'bg-[#EAF6E5]' : 'bg-[#2F4427]';
        textColor = theme === 'light' ? 'text-[#2AA300]' : 'text-[#AAF490]';
        label = 'Yes';
        width = 'w-[56px]'
    }

    return (
        <div
            className={`inline-block text-center px-2 py-1 rounded-full ${width} ${backgroundColor} ${textColor}`}
        >
            {label}
        </div>
    )   
}

export default VerticalProductStatus