import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const BasketIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <>
      {
        theme === 'light' ? (
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_949_7181)">
            <rect x="1" y="1" width="36" height="36" rx="8" fill="#FFFAED"/>
            <rect x="2" y="2" width="34" height="34" rx="7" stroke="white" strokeWidth="2"/>
            <g clipPath="url(#clip0_949_7181)">
            <path d="M9.83325 9.83337H13.1666L15.3999 20.9917C15.4761 21.3754 15.6848 21.72 15.9895 21.9653C16.2942 22.2106 16.6755 22.3409 17.0666 22.3334H25.1666C25.5577 22.3409 25.9389 22.2106 26.2436 21.9653C26.5483 21.72 26.757 21.3754 26.8333 20.9917L28.1666 14H13.9999M17.3333 26.5C17.3333 26.9603 16.9602 27.3334 16.4999 27.3334C16.0397 27.3334 15.6666 26.9603 15.6666 26.5C15.6666 26.0398 16.0397 25.6667 16.4999 25.6667C16.9602 25.6667 17.3333 26.0398 17.3333 26.5ZM26.4999 26.5C26.4999 26.9603 26.1268 27.3334 25.6666 27.3334C25.2063 27.3334 24.8333 26.9603 24.8333 26.5C24.8333 26.0398 25.2063 25.6667 25.6666 25.6667C26.1268 25.6667 26.4999 26.0398 26.4999 26.5Z" stroke="#FBC849" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_d_949_7181" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_949_7181"/>
            <feOffset/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.898039 0 0 0 0 0.905882 0 0 0 0 0.921569 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_949_7181"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_949_7181" result="shape"/>
            </filter>
            <clipPath id="clip0_949_7181">
            <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
            </clipPath>
            </defs>
          </svg>
        ) : (
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_7791_385577)">
            <rect x="1" y="1" width="36" height="36" rx="8" fill="#303C50"/>
            <rect x="2" y="2" width="34" height="34" rx="7" stroke="#495D80" strokeWidth="2"/>
            <g clipPath="url(#clip0_7791_385577)">
            <path d="M9.83398 9.83203H13.1673L15.4007 20.9904C15.4769 21.374 15.6856 21.7187 15.9903 21.9639C16.295 22.2092 16.6762 22.3395 17.0673 22.332H25.1673C25.5584 22.3395 25.9397 22.2092 26.2444 21.9639C26.5491 21.7187 26.7578 21.374 26.834 20.9904L28.1673 13.9987H14.0007M17.334 26.4987C17.334 26.9589 16.9609 27.332 16.5007 27.332C16.0404 27.332 15.6673 26.9589 15.6673 26.4987C15.6673 26.0385 16.0404 25.6654 16.5007 25.6654C16.9609 25.6654 17.334 26.0385 17.334 26.4987ZM26.5007 26.4987C26.5007 26.9589 26.1276 27.332 25.6673 27.332C25.2071 27.332 24.834 26.9589 24.834 26.4987C24.834 26.0385 25.2071 25.6654 25.6673 25.6654C26.1276 25.6654 26.5007 26.0385 26.5007 26.4987Z" stroke="#B38309" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_d_7791_385577" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_7791_385577"/>
            <feOffset/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.158863 0 0 0 0 0.16001 0 0 0 0 0.162305 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7791_385577"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7791_385577" result="shape"/>
            </filter>
            <clipPath id="clip0_7791_385577">
            <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
            </clipPath>
            </defs>
          </svg>
        )
      }
    </>
    
  )
}

export default BasketIcon