import React from "react";

const CarIcon = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 11.8333V1H0.833252V11.8333H13.3333ZM13.3333 11.8333H19.1666V7.66667L16.6666 5.16667H13.3333V11.8333ZM6.66658 13.9167C6.66658 15.0673 5.73384 16 4.58325 16C3.43266 16 2.49992 15.0673 2.49992 13.9167C2.49992 12.7661 3.43266 11.8333 4.58325 11.8333C5.73384 11.8333 6.66658 12.7661 6.66658 13.9167ZM17.4999 13.9167C17.4999 15.0673 16.5672 16 15.4166 16C14.266 16 13.3333 15.0673 13.3333 13.9167C13.3333 12.7661 14.266 11.8333 15.4166 11.8333C16.5672 11.8333 17.4999 12.7661 17.4999 13.9167Z"
        stroke="#171717"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CarIcon;
