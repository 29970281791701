import React, {useContext, useEffect, useMemo, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './css/sidebar.css';
import OdinForgeService from "../../../odinForgeService/OdinForgeService";
import RemovePopup from "../../UI/removePopup/RemovePopup";
import TopBar from "../topbar/TopBar";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SelectedItemContext } from "./SelectedItemContext";
import {UserContext} from "../../../context/UserContext/UserContext";
import {routePermissions} from "../../../permissions/permissions";
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import HomeBtn from './UI/HomeBtn';
import useMediaQuery from '../../../hooks/useMediaQuery';
import ReportsBtn from './UI/ReportsBtn';
import Logo from '../../../assets/icons/Logo';
import DateTimeTimezone from '../../UI/DateTimeTimezone/DateTimeTimezone';
import shouldHideForProductionWebsite from '../../../helpers/shouldHideForProductionWebsite';

export function SideBar() {
    const location = useLocation();
    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(true);
    const { selectedItem, setSelectedItem } = useContext(SelectedItemContext);
    const [isLogout, setIsLogout] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const userContext = useContext(UserContext)!;
    const { permissions, isSuperAdmin, isAdmin } = userContext;

    const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState(false);

    const { theme } = useContext(ThemeContext)!;

    const isMobile = useMediaQuery('(max-width: 1023px)')

    const enableMockedPages = process.env.REACT_APP_ENABLE_MOCKED_PAGES === 'true'
    
    const dropdownItems = [
        { name: 'Sales Pace Report ', link: '/dashboard/sales-pace-report-table' },
        { name: 'Sales Pace Report Line Chart', link: '/dashboard/sales-pace-report-line-chart' },
        { name: 'Sales Performance Report', link: '/dashboard/sales-performance-report' },
        { name: 'Sales Performance Report Line Chart', link: '/dashboard/sales-performance-report-line-chart' },
        { name: 'Transaction Performance Report', link: '/dashboard/transaction-performance-report' },
        { name: 'Retention Report', link: '/dashboard/retention-report' },
        { name: 'Affiliate Sales Summary', link: '/dashboard/affiliate-sales-summary' },
        { name: 'Cancellation Reasons (Orders)', link: '/dashboard/cancellationReasons' },
        { name: 'Cancellation Reasons (Subscriptions)', link: '/dashboard/cancellationSubscriptionReasons' },
        { name: 'Forecasted Rebills Report', link: '/dashboard/forecasted-rebills-report' },
        { name: 'Conversion Report', link: '/dashboard/conversion-report' },
        { name: 'Decline Response Report', link: '/dashboard/decline-response-report' },
        { name: 'BIN Routing Performance Report', link: '/dashboard/bin-routing-performance-report' },
        { name: 'Variance Report', link: '/dashboard/variance-report' },
        { name: 'Card Processing Report', link: '/dashboard/card-processing-report' },
        { name: 'Duplicate Customer Report', link: '/dashboard/duplicate-customer-report' }
        // ...(!shouldHideForProductionWebsite() ? [{ name: 'Duplicate Customer Report', link: '/dashboard/duplicate-customer-report' }] : []),
    ];

    const allowedDropDownItems = dropdownItems.filter(item => {
        const permissionsForLink = routePermissions[item.link];
        if (!permissionsForLink) return true;
        return isSuperAdmin || isAdmin || permissionsForLink.some(permission => permissions.includes(permission));
    });

    useEffect(() => {
        const currentPath = location.pathname;
        const normalizedPath = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;
    
        const activeDropdownItem = dropdownItems.find(item => normalizedPath === item.link);
        
        if (activeDropdownItem) {
            setSelectedItem(activeDropdownItem.link);
        } else if (location.pathname.includes('/dashboard/profileInfo')) {
            setSelectedItem('profile');
        } else if (normalizedPath === '/dashboard') {
            setSelectedItem('reports');
        } else {
            setSelectedItem(null);
        }
    }, [location, setSelectedItem]);

    const handleItemClick = (itemLink: string) => {
        setSelectedItem(itemLink);
    };

    const handleButtonClick = (item: string) => {
        setSelectedItem(item);

        if (item === 'home') {
            closeMobileSidebar();
        } else if (item === 'logout'){
            handleLogoutClick()
            setSelectedItem(null);
        } else if (item === 'profile') {
            closeMobileSidebar();
        }
    };

    const buttonClass = (item: string) => {
       return  (
            `${selectedItem === item
                ? `sidebar-button rounded-tr-lg rounded-br-lg border-l-2 !bg-blue-200 dark:!bg-odin-dark-blue-medium_2 border-[#0079FF] flex items-center w-full p-2 pl-4 text-odin-light-content dark:text-odin-dark-content`
                : `sidebar-button flex items-center hover-blue p-2 pl-4 focus:outline-none text-odin-light-content dark:text-odin-dark-content`}`
        )
    };

    const iconStyle = (item: string) => ({
        filter: selectedItem === item
            ? 'brightness(0) saturate(100%) invert(27%) sepia(95%) saturate(6393%) hue-rotate(204deg) brightness(91%) contrast(101%)'
            : '',
    });

    const isHomeActive = useMemo(() => {
        let result = false

        if (location.pathname === '/dashboard' || location.pathname === '/dashboard/') {
            result = true;
        }

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dropdownItems]);

    const isReportsActive = useMemo(() => {
        let result = dropdownItems.some(item => location.pathname.includes(item.link || '/dashboard'));

        return result;
    }, [location]);

    const isLinkActive = (link: string) => {
        let result = dropdownItems.some(item => location.pathname.includes(link));
        return result;
    };

    function openMobileSidebar() {
        setIsOpenMobileSidebar(true);
    }

    function closeMobileSidebar() {
        setIsOpenMobileSidebar(false);
    }

    const handleOpenDialog = () => {
        setOpenDialog (true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleLogoutClick = () => {
        setIsLogout(true)
        handleCloseDialog()
    }

    const logoutAction = () => {
        OdinForgeService.instance().logout();
        setSelectedItem(null);
        handleCloseDialog()
        const url = process.env.REACT_APP_BASE_URL

        if (isSuperAdmin) { 
            navigate('/');
        } else if (url === 'https://staging-api.odinforgebi.com/') {
            window.location.href = 'https://crm2.staging.swisspriv.com/login'; 
        } else {
            window.location.href = 'https://dashboard.swisscrm.com/login'
        }
    }

    const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <div className='w-full relative'>
            {isLogout && <RemovePopup onClose={() => setIsLogout(false)} onNoClick={() => setIsLogout(false)}
                                      removeText="Do you really want to logout?"
                                      onYesClick={() => logoutAction()}/>}
            <div className="fixed top-0 w-full fixed-topbar z-0 tablet-size:z-[1] bg-odin-light-blue-extra_light dark:bg-odin-dark-blue-extra_dark">
                <TopBar 
                    handleButtonClick={handleButtonClick} 
                    openMobileSidebar={openMobileSidebar} 
                    handleLogoutClick={handleLogoutClick}
                    handleOpenDialog={handleOpenDialog}
                    handleCloseDialog={handleCloseDialog}
                    openDialog={openDialog}
                    setIsLogout={setIsLogout}
                />
            </div>
            <div className={`bg-odin-light-blue-extra_light dark:bg-odin-dark-blue-extra_dark  flex flex-col justify-between fixed top-0 tablet-size:pt-4 tablet-size:top-[62px] left-0 h-screen tablet-size:h-[calc(100vh_-_64px)] transform transition-transform duration-300 ease-in-out ${isOpenMobileSidebar ? "translate-x-0" : "tablet-size:translate-x-0 -translate-x-full"} w-64 tablet-size:w-60`}>
                <div className="overflow-y-auto flex flex-col justify-between h-screen tablet-size:h-[calc(100vh_-_64px)]">
                    <nav className='flex flex-col gap-3' >
                        <div>
                            {
                                theme === 'dark' ? (
                                    <div className="block tablet-size:hidden px-4 py-[14px] relative">
                                        <Logo width='104px' height='36px' />
                                    </div>
                                ) : (
                                    <div className="block tablet-size:hidden px-4 py-[14px] relative">
                                        <Logo width='104px' height='36px' />
                                    </div>
                                )
                            }
                            {isOpenMobileSidebar && (
                                <div
                                    className="fixed  top-0 left-64 h-full w-[100vw] bg-black bg-opacity-50 z-20"
                                    onClick={closeMobileSidebar}
                                ></div>
                            )}
                            <div className={`relative flex`} style={{marginTop: isMobile ? '14px' : ''}}>
                                <HomeBtn isHomeActive={isHomeActive} iconStyle={iconStyle} buttonClass={buttonClass} handleButtonClick={handleButtonClick}/>
                            </div>

                        </div>
                        <ReportsBtn 
                                toggleDropdown={toggleDropdown} 
                                dropdownOpen={dropdownOpen} 
                                buttonClass={buttonClass} 
                                handleButtonClick={handleButtonClick} 
                                isReportsActive={isReportsActive}
                            />
                            {dropdownOpen && (
                                <div className='ml-[30px]'>
                                    {allowedDropDownItems.map((item, index) => (
                                        <div key={index} className='flex items-center'>
                                            <div
                                                className={`flex items-center text-blue h-1 w-1 rounded-full justify-center`}>
                                                {selectedItem === item.link &&
                                                    <FontAwesomeIcon icon={faCircle}
                                                                     className='svg-icon text-[#0079FF] h-1.5 w-1.5'/>
                                                }
                                            </div>
                                            <div className='pl-2' onClick={closeMobileSidebar}>
                                                <Link
                                                    to={item.link}
                                                    className={`text-[12px] block text-odin-light-content dark:text-odin-dark-content p-2 pl-2 transition duration-300 hover:text-[#0079FF] ${isLinkActive(item.link) && 'font-medium'}`}
                                                    onClick={() => handleItemClick(item.link)}
                                                >
                                                    {item.name}
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        {/* <ProfileBtn iconStyle={iconStyle} buttonClass={buttonClass} capitalizeFirstLetter={capitalizeFirstLetter} handleButtonClick={handleButtonClick} /> */}



                    </nav>

                    {
                    // isSuperAdmin || isAdmin ? (
                        <div className='p-4 mt-10'>
                            <DateTimeTimezone />
                        </div>
                    // ) : null
                 }
                </div>
                
                
                
                {/* <LogoutBtn iconStyle={iconStyle} buttonClass={buttonClass} capitalizeFirstLetter={capitalizeFirstLetter} handleButtonClick={handleButtonClick}  /> */}
            </div>
        </div>

    );
}


