import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const PayCard = () => {
    const { theme } = useContext(ThemeContext)!;

  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.833313 5.83203H19.1666M2.49998 0.832031H17.5C18.4205 0.832031 19.1666 1.57822 19.1666 2.4987V12.4987C19.1666 13.4192 18.4205 14.1654 17.5 14.1654H2.49998C1.5795 14.1654 0.833313 13.4192 0.833313 12.4987V2.4987C0.833313 1.57822 1.5795 0.832031 2.49998 0.832031Z" stroke={theme === 'light' ? "#171717" : "#EBEFF7"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PayCard