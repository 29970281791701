import {
    VarianceReportData,
    VarianceReporHourData,
    VarianceReporPartnerData,
    MetricData,
    HourMetrics,
    PartnerMetrics,
} from "./types/variance-report.types";

// Helper function to parse a metric
const parseMetric = (
    current: number | null | string,
    previous: number | null | string,
    variance: number | null | string
): MetricData => {
    return {
        current: current !== null && current !== "" ? parseFloat(current.toString()) : '---',
        previous: previous !== null && previous !== "" ? parseFloat(previous.toString()) : '---',
        variance: typeof variance === "number"
            ? `${variance.toFixed(2)}`
            : variance !== null && variance !== ""
            ? variance.toString()
            : "---",
    };
};

// Helper function to calculate variance
const calculateVariance = (current: number, previous: number): string => {
    if (previous === 0) return current === 0 ? "0" : '---'; // Avoid division by zero
    const variance = ((current - previous) / previous) * 100;
    return `${variance.toFixed(2)}`;
};

// Helper function to aggregate metrics for summary
const aggregateMetrics = (metrics: MetricData[]): MetricData => {
    const validCurrents = metrics
        .map((metric) => metric.current)
        .filter((value): value is number => typeof value === 'number' && !isNaN(value));
    const validPrevious = metrics
        .map((metric) => metric.previous)
        .filter((value): value is number => typeof value === 'number' && !isNaN(value));

    const totalCurrent = validCurrents.reduce((sum, value) => sum + value, 0);
    const totalPrevious = validPrevious.reduce((sum, value) => sum + value, 0);
    const variance = calculateVariance(totalCurrent, totalPrevious);

    return {
        current: totalCurrent,
        previous: totalPrevious,
        variance,
    };
};

export const transformData = (
    data: VarianceReportData[],
    viewMode?: string
): (VarianceReporHourData | VarianceReporPartnerData)[] => {

    // If viewMode is 'affiliates', group data by affiliate
    if (viewMode === 'affiliates') {
        const affiliateMap: { [key: string]: VarianceReporPartnerData } = {};

        data.forEach((item) => {
            const partner = item.affiliate;
            if (!affiliateMap[partner]) {
                affiliateMap[partner] = {
                    partner,
                    summary: {
                        clicks: { current: 0, previous: 0, variance: 0 },
                        conversions: { current: 0, previous: 0, variance: 0 },
                        events: { current: 0, previous: 0, variance: 0 },
                        revenue: { current: 0, previous: 0, variance: 0 },
                        epc: { current: 0, previous: 0, variance: 0 },
                    },
                    hours: [],
                    currency: item.currency,
                };
            }

            const hourMetrics: HourMetrics = {
                hour: String(item.hour),
                clicks: parseMetric(item.clicks_current, item.clicks_previous, item.clicks_variance),
                conversions: parseMetric(item.conversions_current, item.conversions_previous, item.conversions_variance),
                events: parseMetric(item.events_current, item.events_previous, item.events_variance),
                revenue: parseMetric(item.revenue_current, item.revenue_previous, item.revenue_variance),
                epc: parseMetric(item.epc_current, item.epc_previous, item.epc_variance),
                currency: item.currency,
            };

            affiliateMap[partner].hours.push(hourMetrics);
        });

        // Calculate summary for each affiliate
        Object.values(affiliateMap).forEach((partnerData) => {
            const allClicks = partnerData.hours.map(hour => hour.clicks);
            const allConversions = partnerData.hours.map(hour => hour.conversions);
            const allEvents = partnerData.hours.map(hour => hour.events);
            const allRevenue = partnerData.hours.map(hour => hour.revenue);
            const allEpc = partnerData.hours.map(hour => hour.epc);

            partnerData.summary = {
                clicks: aggregateMetrics(allClicks),
                conversions: aggregateMetrics(allConversions),
                events: aggregateMetrics(allEvents),
                revenue: aggregateMetrics(allRevenue),
                epc: aggregateMetrics(allEpc),
            };
        });

        // Return as a list of VarianceReportPartnerData
        return Object.values(affiliateMap);
    }

    // If viewMode is 'hours', group data by hour
    if (viewMode === 'hours') {
        const hourMap: { [key: string]: VarianceReporHourData } = {};

        data.forEach((item) => {
            const hour = String(item.hour);
            if (!hourMap[hour]) {
                hourMap[hour] = {
                    hour,
                    summary: {
                        clicks: { current: 0, previous: 0, variance: 0 },
                        conversions: { current: 0, previous: 0, variance: 0 },
                        events: { current: 0, previous: 0, variance: 0 },
                        revenue: { current: 0, previous: 0, variance: 0 },
                        epc: { current: 0, previous: 0, variance: 0 },
                    },
                    partners: [],
                    currency: item.currency,
                };
            }

            const partnerMetrics: PartnerMetrics = {
                partner: item.affiliate,
                clicks: parseMetric(item.clicks_current, item.clicks_previous, item.clicks_variance),
                conversions: parseMetric(item.conversions_current, item.conversions_previous, item.conversions_variance),
                events: parseMetric(item.events_current, item.events_previous, item.events_variance),
                revenue: parseMetric(item.revenue_current, item.revenue_previous, item.revenue_variance),
                epc: parseMetric(item.epc_current, item.epc_previous, item.epc_variance),
                currency: item.currency,
            };

            hourMap[hour].partners.push(partnerMetrics);
        });

        // Calculate summary for each hour
        Object.values(hourMap).forEach((hourData) => {
            const allClicks = hourData.partners.map(partner => partner.clicks);
            const allConversions = hourData.partners.map(partner => partner.conversions);
            const allEvents = hourData.partners.map(partner => partner.events);
            const allRevenue = hourData.partners.map(partner => partner.revenue);
            const allEpc = hourData.partners.map(partner => partner.epc);

            hourData.summary = {
                clicks: aggregateMetrics(allClicks),
                conversions: aggregateMetrics(allConversions),
                events: aggregateMetrics(allEvents),
                revenue: aggregateMetrics(allRevenue),
                epc: aggregateMetrics(allEpc),
            };
        });

        // Return as a list of VarianceReportHourData
        return Object.values(hourMap);
    }

    return [];
};
