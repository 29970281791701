
import getSymbolFromCurrency from "currency-symbol-map";
import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { HourMetrics, VarianceReporHourData, VarianceReporPartnerData } from "./types/variance-report.types";

const transformDataToCSVFormat = (
    data: VarianceReporHourData[] | VarianceReporPartnerData[]
  ): { [key: string]: string | number | null }[] => {
    if (!data || !data.length) {
      return [];
    }
  
    const flattenedData: { [key: string]: string | number | null }[] = [];
  
    const isHourData = (item: VarianceReporHourData | VarianceReporPartnerData): item is VarianceReporHourData => {
      return 'hour' in item && 'summary' in item && 'partners' in item;
    };
  
    const isPartnerData = (item: VarianceReporHourData | VarianceReporPartnerData): item is VarianceReporPartnerData => {
      return 'partner' in item && 'summary' in item && 'hours' in item;
    };
  
    if (isHourData(data[0])) {
      data.forEach((hourData) => {
        flattenedData.push({
            Hour: (hourData as VarianceReporHourData).hour,
            Partner: 'Summary',
            "Clicks Previous": hourData.summary.clicks.previous,
            "Clicks Current": hourData.summary.clicks.current,
            "Clicks Variance": (hourData.summary.clicks.variance || hourData.summary.clicks.variance === '0') && hourData.summary.clicks.variance !== '---' ? Number(hourData.summary.clicks.variance)?.toFixed(2) + '%' : hourData.summary.clicks.variance,
            "Conversions Previous": hourData.summary.conversions.previous,
            "Conversions Current": hourData.summary.conversions.current,
            "Conversions Variance": (hourData.summary.conversions.variance || hourData.summary.conversions.variance === '0') && hourData.summary.conversions.variance !== '---' ? Number(hourData.summary.conversions.variance)?.toFixed(2) + '%' : hourData.summary.conversions.variance,
            "Events Previous": hourData.summary.events.previous,
            "Events Current": hourData.summary.events.current,
            "Events Variance": (hourData.summary.events.variance || hourData.summary.events.variance === '0') && hourData.summary.events.variance !== '---' ? Number(hourData.summary.events.variance)?.toFixed(2) + '%' : hourData.summary.events.variance,
            "Revenue Previous": hourData.summary.revenue.previous && Number(hourData.summary.revenue.previous) ? `${hourData.currency && getSymbolFromCurrency(hourData.currency)}${Number(hourData.summary.revenue.previous).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Current": hourData.summary.revenue.current && Number(hourData.summary.revenue.current) ? `${hourData.currency && getSymbolFromCurrency(hourData.currency)}${Number(hourData.summary.revenue.current).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Variance": (hourData.summary.revenue.variance || hourData.summary.revenue.variance === '0') && hourData.summary.revenue.variance !== '---' ? Number(hourData.summary.revenue.variance)?.toFixed(2) + '%' : hourData.summary.revenue.variance,
            "EPC Previous": hourData.summary.epc.previous && Number(hourData.summary.epc.previous) ? `${Number(hourData.summary.epc.previous).toFixed(2)}`  : '---',
            "EPC Current": hourData.summary.epc.current && Number(hourData.summary.epc.current) ? `${Number(hourData.summary.epc.current).toFixed(2)}`  : '---',
            "EPC Variance":(hourData.summary.epc.variance || hourData.summary.epc.variance === '0') && hourData.summary.epc.variance !== '---' ? Number(hourData.summary.epc.variance)?.toFixed(2) + '%' : hourData.summary.epc.variance,
        });
  
        (hourData as VarianceReporHourData).partners.forEach((partnerMetrics) => {
          flattenedData.push({
            Hour: (hourData as VarianceReporHourData).hour,
            Partner: capitalizeSpecificWords(partnerMetrics.partner),
            "Clicks Previous": partnerMetrics.clicks.previous,
            "Clicks Current": partnerMetrics.clicks.current,
            "Clicks Variance": (partnerMetrics.clicks.variance || partnerMetrics.clicks.variance === '0') && partnerMetrics.clicks.variance !== '---' ? Number(partnerMetrics.clicks.variance)?.toFixed(2) + '%' : partnerMetrics.clicks.variance,
            "Conversions Previous": partnerMetrics.conversions.previous,
            "Conversions Current": partnerMetrics.conversions.current,
            "Conversions Variance": (partnerMetrics.conversions.variance || partnerMetrics.conversions.variance === '0') && partnerMetrics.conversions.variance !== '---' ? Number(partnerMetrics.conversions.variance)?.toFixed(2) + '%' : partnerMetrics.conversions.variance,
            "Events Previous": partnerMetrics.events.previous,
            "Events Current": partnerMetrics.events.current,
            "Events Variance": (partnerMetrics.events.variance || partnerMetrics.events.variance === '0') && partnerMetrics.events.variance !== '---' ? Number(partnerMetrics.events.variance)?.toFixed(2) + '%' : partnerMetrics.events.variance,
            "Revenue Previous": partnerMetrics.revenue.previous && Number(partnerMetrics.revenue.previous) ? `${partnerMetrics.currency && getSymbolFromCurrency(partnerMetrics.currency)}${Number(partnerMetrics.revenue.previous).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Current": partnerMetrics.revenue.current && Number(partnerMetrics.revenue.current) ? `${partnerMetrics.currency && getSymbolFromCurrency(partnerMetrics.currency)}${Number(partnerMetrics.revenue.current).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Variance": (partnerMetrics.revenue.variance || partnerMetrics.revenue.variance === '0') && partnerMetrics.revenue.variance !== '---' ? Number(partnerMetrics.revenue.variance)?.toFixed(2) + '%' : partnerMetrics.revenue.variance,
            "EPC Previous": partnerMetrics.epc.previous && Number(partnerMetrics.epc.previous) ? `${Number(partnerMetrics.epc.previous).toFixed(2)}`  : '---',
            "EPC Current": partnerMetrics.epc.current && Number(partnerMetrics.epc.current) ? `${Number(partnerMetrics.epc.current).toFixed(2)}`  : '---',
            "EPC Variance": (partnerMetrics.epc.variance || partnerMetrics.epc.variance === '0') && partnerMetrics.epc.variance !== '---' ? Number(partnerMetrics.epc.variance)?.toFixed(2) + '%' : partnerMetrics.epc.variance,
          });
        });
      });
    } else if (isPartnerData(data[0])) {
      data.forEach((partnerData) => {
        flattenedData.push({
            Partner: capitalizeSpecificWords((partnerData as VarianceReporPartnerData).partner),
            Hour: "Summary",
            "Clicks Previous": partnerData.summary.clicks.previous,
            "Clicks Current": partnerData.summary.clicks.current,
            "Clicks Variance": (partnerData.summary.clicks.variance || partnerData.summary.clicks.variance === '0') && partnerData.summary.clicks.variance !== '---' ? Number(partnerData.summary.clicks.variance)?.toFixed(2) + '%' : partnerData.summary.clicks.variance,
            "Conversions Previous": partnerData.summary.conversions.previous,
            "Conversions Current": partnerData.summary.conversions.current,
            "Conversions Variance": (partnerData.summary.conversions.variance || partnerData.summary.conversions.variance === '0') && partnerData.summary.conversions.variance !== '---' ? Number(partnerData.summary.conversions.variance)?.toFixed(2) + '%' : partnerData.summary.conversions.variance,
            "Events Previous": partnerData.summary.events.previous,
            "Events Current": partnerData.summary.events.current,
            "Events Variance": (partnerData.summary.events.variance || partnerData.summary.events.variance === '0') && partnerData.summary.events.variance !== '---' ? Number(partnerData.summary.events.variance)?.toFixed(2) + '%' : partnerData.summary.events.variance,
            "Revenue Previous": partnerData.summary.revenue.previous && Number(partnerData.summary.revenue.previous) ? `${partnerData.currency && getSymbolFromCurrency(partnerData.currency)}${Number(partnerData.summary.revenue.previous).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Current": partnerData.summary.revenue.current && Number(partnerData.summary.revenue.current) ? `${partnerData.currency && getSymbolFromCurrency(partnerData.currency)}${Number(partnerData.summary.revenue.current).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Variance": (partnerData.summary.revenue.variance || partnerData.summary.revenue.variance === '0') && partnerData.summary.revenue.variance !== '---' ? Number(partnerData.summary.revenue.variance)?.toFixed(2) + '%' : partnerData.summary.revenue.variance,
            "EPC Previous": partnerData.summary.epc.previous && Number(partnerData.summary.epc.previous) ? `${Number(partnerData.summary.epc.previous).toFixed(2)}`  : '---',
            "EPC Current": partnerData.summary.epc.current && Number(partnerData.summary.epc.current) ? `${Number(partnerData.summary.epc.current).toFixed(2)}`  : '---',
            "EPC Variance": (partnerData.summary.epc.variance || partnerData.summary.epc.variance === '0') && partnerData.summary.epc.variance !== '---' ? Number(partnerData.summary.epc.variance)?.toFixed(2) + '%' : partnerData.summary.epc.variance,
        });
  
        (partnerData as VarianceReporPartnerData).hours.forEach((hourMetrics: HourMetrics) => {
          flattenedData.push({
            Partner: capitalizeSpecificWords((partnerData as VarianceReporPartnerData).partner),
            Hour: hourMetrics.hour,
            "Clicks Previous": hourMetrics.clicks.previous,
            "Clicks Current": hourMetrics.clicks.current,
            "Clicks Variance": (hourMetrics.clicks.variance || hourMetrics.clicks.variance === '0') && hourMetrics.clicks.variance !== '---' ? Number(hourMetrics.clicks.variance)?.toFixed(2) + '%' : hourMetrics.clicks.variance,
            "Conversions Previous": hourMetrics.conversions.previous,
            "Conversions Current": hourMetrics.conversions.current,
            "Conversions Variance": (hourMetrics.conversions.variance || hourMetrics.conversions.variance === '0') && hourMetrics.conversions.variance !== '---' ? Number(hourMetrics.conversions.variance)?.toFixed(2) + '%' : hourMetrics.conversions.variance,
            "Events Previous": hourMetrics.events.previous,
            "Events Current": hourMetrics.events.current,
            "Events Variance": (hourMetrics.events.variance || hourMetrics.events.variance === '0') && hourMetrics.events.variance !== '---' ? Number(hourMetrics.events.variance)?.toFixed(2) + '%' : hourMetrics.events.variance,
            "Revenue Previous": hourMetrics.revenue.previous && Number(hourMetrics.revenue.previous) ? `${partnerData.currency && getSymbolFromCurrency(partnerData.currency)}${Number(hourMetrics.revenue.previous).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Current": hourMetrics.revenue.current && Number(hourMetrics.revenue.current) ? `${partnerData.currency && getSymbolFromCurrency(partnerData.currency)}${Number(hourMetrics.revenue.current).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`  : '---',
            "Revenue Variance": (hourMetrics.revenue.variance || hourMetrics.revenue.variance === '0') && hourMetrics.revenue.variance !== '---' ? Number(hourMetrics.revenue.variance)?.toFixed(2) + '%' : hourMetrics.revenue.variance,
            "EPC Previous": hourMetrics.epc.previous && Number(hourMetrics.epc.previous) ? `${Number(hourMetrics.epc.previous).toFixed(2)}`  : '---',
            "EPC Current": hourMetrics.epc.current && Number(hourMetrics.epc.current) ? `${Number(hourMetrics.epc.current).toFixed(2)}`  : '---',
            "EPC Variance": (hourMetrics.epc.variance || hourMetrics.epc.variance === '0') && hourMetrics.epc.variance !== '---' ? Number(hourMetrics.epc.variance)?.toFixed(2) + '%' : hourMetrics.epc.variance,
          });
        });
      });
    } else {
      throw new Error('Data format not recognized');
    }
  
    return flattenedData;
  };
  
  export default transformDataToCSVFormat;