import React, { useContext } from "react";
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';

const ProductIcon = () => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 7.33329L5.25 3.00829M1.725 5.29996L9 9.50829L16.275 5.29996M9 17.9V9.49996M16.5 12.8333V6.16663C16.4997 5.87435 16.4225 5.5873 16.2763 5.33426C16.13 5.08122 15.9198 4.87109 15.6667 4.72496L9.83333 1.39163C9.57997 1.24535 9.29256 1.16833 9 1.16833C8.70744 1.16833 8.42003 1.24535 8.16667 1.39163L2.33333 4.72496C2.08022 4.87109 1.86998 5.08122 1.72372 5.33426C1.57745 5.5873 1.5003 5.87435 1.5 6.16663V12.8333C1.5003 13.1256 1.57745 13.4126 1.72372 13.6657C1.86998 13.9187 2.08022 14.1288 2.33333 14.275L8.16667 17.6083C8.42003 17.7546 8.70744 17.8316 9 17.8316C9.29256 17.8316 9.57997 17.7546 9.83333 17.6083L15.6667 14.275C15.9198 14.1288 16.13 13.9187 16.2763 13.6657C16.4225 13.4126 16.4997 13.1256 16.5 12.8333Z"
        stroke={theme === 'light' ? "#171717" : "#EBEFF7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductIcon;
