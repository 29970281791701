import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import Routes from "./Enums/Routes";
import SessionManager from "./SessionManager";
import HttpStatus from "./Enums/HttpStatus";
import sessionManager from "./SessionManager";
import {generateMockVarianceData} from "../components/pages/reports/VarianceReport/mockData/mockVarianceGenerator";
import { dashboardOverviewFilterData, mockDashboardData, mockDataConfig } from "../components/pages/reports/dashboardOverview/data/mockdata";
import UserActivityEnum from "../types/enums/UserActivity";
import { showModalGlobal } from "../globals";
import { ReportIds } from "./Enums/ReportName";
import { BinRoutingPerformaceReportResponse } from "../components/pages/reports/BinRoutingPerformaceReport/types/bin-routing-performace-report.types";
import { mockDataChargebackSummaryReport } from "../components/pages/reports/ChargebackSummaryReport/utils/data";
import { filterMockData, mockDataHours, mockDataPartner, newMockDataVarianceReport } from "../components/pages/reports/VarianceReport2/utils/data";
import { mockDataCardProcessingReport } from "../components/pages/reports/CardProcessingReport/utils/data";
import { DuplicateCustomerReportResponse } from "../components/pages/reports/DuplicateCustomerReport/types/duplicate-customer-report.types";
import { duplicateCustomerReportMockData } from "../components/pages/reports/DuplicateCustomerReport/data/mockData";
import { data } from "../components/pages/reports/VarianceReport/mockData/mockVarianceData";
import mockDataDeclineResponeReport from "../components/pages/reports/DeclineResponseReport/utils/data";

interface LoginRequest {
    email: string;
    password: string;
}

class OdinForgeService {
    private static instanceCache?: OdinForgeService;
    public baseURL = process.env.REACT_APP_BASE_URL;
    public axiosClient!: AxiosInstance;
    private controller: AbortController | null = null;
    private controllers: { [key: string]: AbortController } = {};

    public static instance(): OdinForgeService {
        if (!this.instanceCache) {
            this.instanceCache = new this();
            this.instanceCache.init()
        }

        return this.instanceCache;
    }

    private init() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL,
            timeout: 60000
        })

        this.axiosClient.defaults.headers.common['Content-Type'] = 'application/json';
        this.axiosClient.defaults.headers.common['Accept'] = 'application/json';

        this.axiosClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            const token =  SessionManager.instance().getToken();
            const rememberMe = localStorage.getItem('rememberMe') === 'true';
            if (token) {

                SessionManager.instance().saveToken(token, rememberMe);
            }
            return config;
        });

        const loginRequiredStatusCodes = [HttpStatus.Unauthorized];

        const tooMuchRequestsCode = [HttpStatus.TooMuchRequests];

        this.axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && loginRequiredStatusCodes.includes(error.response.status)) {
                    localStorage.clear();

                    // redirect user to / page with window object
                    // check if url already is not / to avoid infinite loop
                    if (window.location.pathname !== '/') {
                        window.location.href = '/'
                    }
                } else if (error.response && tooMuchRequestsCode.includes(error.response.status)) {
                    
                    // localStorage.clear();

                    const currentTime = new Date().getTime();
                    localStorage.setItem('tooManyRequests', JSON.stringify({
                        value: 'true',
                        timestamp: currentTime
                    }));

                    // if (window.location.pathname !== '/') {
                    //     window.location.href = '/'
                    // }

                    // localStorage.clear()

                    if (showModalGlobal) {
                        showModalGlobal('Your account has exceeded the allowed number of database queries, so you have been placed on a 2 minute cooldown. Please try again later.');
                    }

                    return

                } else {
                    console.error("API Error:", error.response ? error.response.data : error.message);
                }

                return Promise.reject(error);
            }
        );
    }

    // Method to cancel a request by identifier
    public cancelRequest(requestId: string) {
        const controller = this.controllers[requestId];
        if (controller) {
            controller.abort();
            delete this.controllers[requestId];
        }
    }

    private getAuthToken() {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            return false;
        }

        return {
            Authorization: `Bearer ${accessToken}`
        };
    }

    public login(request: LoginRequest, rememberMe: boolean) {
        return this.axiosClient.post(Routes.login, request)
            .then((response) => {
                const accessToken = response.data.access_token;
                SessionManager.instance().saveToken(accessToken,  rememberMe);
                SessionManager.instance().saveEmail(request['email'])
                return response;
            })
    }

    public forgotPassword( email: string) {
        return this.axiosClient.post(`${Routes.forgotPassword}?email=${email}`)
    }

    public resetPassword(email: string, password: string, password_confirmation: string, token: string) {
        return this.axiosClient.post(`${Routes.resetPassword}?email=${email}&password=${password}&password_confirmation=${password_confirmation}&token=${token}`)
    }

    public getSales(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken();
        if (!headers) {
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.sales, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Sales Pace Report data:', error);
            throw error;
        });
    }

    public getSaleGraphFilterData(
        start_date: string, 
        end_date: string, 
        timezone: string, 
        tenantId: null | string | number,
        requestId: string | number | null | undefined,
        isSuperAdmin?: boolean, 
        superTenant?: string | null,
        dataRangeParam?: string
    ) {
        if (!start_date || !end_date) {
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({ data: null });
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        // Clean and validate parameters
        const cleanParam = (param: any) => {
            if (typeof param === 'string') {
                return param.trim();
            }
            return param;
        };
    
        const cleanedStartDate = cleanParam(start_date);
        const cleanedEndDate = cleanParam(end_date);
        const cleanedTimezone = cleanParam(timezone);
        const cleanedTenantId = tenantId !== null && tenantId !== undefined ? cleanParam(tenantId) : null;
        const cleanedSuperTenant = superTenant !== null && superTenant !== undefined ? cleanParam(superTenant) : null;
    
        const params: any = {
            start_date: cleanedStartDate,
            end_date: cleanedEndDate,
            timezone: cleanedTimezone,
            date_range: dataRangeParam
        };
    
        if (isSuperAdmin && cleanedSuperTenant) {
            params['tenant'] = cleanedSuperTenant.toString();
        } else if (cleanedTenantId) {
            params['tenant'] = cleanedTenantId.toString();
        }
    
        return this.axiosClient.get(Routes.getSaleGraphFilterData, {
            params,
            headers: {
                Authorization: `Bearer ${SessionManager.instance().getToken()}`
            },
            signal
        });
    }   

    public getSaleGraphData(params: URLSearchParams, requestId: string | number | null | undefined){     
        const headers = this.getAuthToken();
        if (!headers) {
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.getSaleGraphData, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching sales pace data:', error);
            throw error;
        });
    }

    public logout(){
        localStorage.clear();
        sessionManager.clearSession();
        // window.location.href = Routes.loginUI + '?t=' + new Date().getTime();
        // window.addEventListener('popstate', function(event) {
        //     if (window.location.href === Routes.loginUI) {
        //         window.history.forward();
        //     }
        // });
    }

    public isAuthenticated(): boolean{
        const token = SessionManager.instance().getToken();
        return !!token;
    }
    public postReportConfig(requestBody:object,update: boolean, reportId: string | number | null | undefined) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken){
            console.log('Access token not found in localstorage');
            return Promise.reject('Access token not found');
        }

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        if (update){
            return this.axiosClient.put(`${Routes.reportConfig}/${reportId}`, requestBody, {headers})
                .then((response)=>{
                    return response;
                })
                .catch((error)=>{
                    return Promise.reject(error);
                });
        }
        return this.axiosClient.post(Routes.reportConfig, requestBody, {headers})
            .then((response)=>{
                return response;
            })
            .catch((error)=>{
                return Promise.reject(error);
            });
    }

    public deleteReportConfig(reportId: string | number | null | undefined) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.log('Access token not found in localStorage');
            return Promise.reject('Access token not found');
        }

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        return this.axiosClient.delete(`${Routes.reportConfig}/${reportId}`, { headers })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    public getReportConfig(reportName: string) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.log('Access token not found in localStorage');
            return Promise.reject('Access token not found');
        }

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };
        const url = `${Routes.getReportConfig}?report_name=${encodeURIComponent(reportName)}`;
        return this.axiosClient.get(url, { headers })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    public postLineChartConfig(reportName: string, geoName: string[], dateRange: number, affiliateName: string[], offerNames: string[], update: boolean, reportId: null | string | number | undefined, startDate?: any, endDate?: any) {
        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.log('Access token not found in localstorage');
            return Promise.reject('Access token not found');
        }

        const requestBody = {
            filters: {
                date_range: dateRange,
                geo_name: geoName,
                affiliate_name: affiliateName,
                offer_name: offerNames,
                start_date: startDate ? startDate.format('YYYY-MM-DD') : undefined,
                end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined
            },
            report_name: reportName
        };

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        const config = {
            headers,
            signal: this.controller.signal
        };

        const requestPromise = update
            ? this.axiosClient.put(`${Routes.reportConfig}/${reportId}`, requestBody, config)
            : this.axiosClient.post(Routes.reportConfig, requestBody, config);

        return requestPromise
            .then((response: any) => response)
            .catch((error) => {
                if (this.controller?.signal.aborted) {
                    console.log('Request aborted');
                }
        });
    }

    public getLineChartConfig(reportName: string) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.log('Access token not found in localStorage');
            return Promise.reject('Access token not found');
        }

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };
        const url = `${Routes.getReportConfig}?report_name=${encodeURIComponent(reportName)}`;

        return this.axiosClient.get(url, { headers })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }


    public deleteLineChartConfig(reportId: number) {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.log('Access token not found in localStorage');
            return Promise.reject('Access token not found');
        }

        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        return this.axiosClient.delete(`${Routes.reportConfig}/${reportId}`, { headers })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }


    public getProfileInfo() {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access token not found');
        }

        const url = `${Routes.profile}`

        return this.axiosClient.get(url, {headers})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error)
            });
    }

    public updateProfileInfo(request: object) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access token not found');
        }

        return this.axiosClient.put(`${Routes.profile}`, request, {headers})
            .then((response) => {
                return response
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    }

    public updatePassword(request: object) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access token not found');
        }

        return this.axiosClient.put(`${Routes.changePassword}`, request, {headers})
            .then((response) => {
                return response
            })
            .catch((error) => {
                return Promise.reject((error))
            })
    }

    public getTransactionData(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken();
        if (!headers){
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        return this.axiosClient.get(Routes.transactionReport, {
            params,
            headers,
            signal
        }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        });
    }
    

    public getAffiliateData(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken();
        if (!headers) {
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        return this.axiosClient.get(Routes.affiliateReport, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Affiliate Report data:', error);
            throw error;
        });
    }

    public getSalesPerformanceData(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken();
        if (!headers) {
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.salesPerformanceReport, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Performance Report data:', error);
            throw error;
        });
    }

    public getSalesPerformanceGraphFilterData(
        start_date: string, 
        end_date: string, 
        timezone: string, 
        tenantId: null | string | number,
        requestId: string | number | null | undefined,
        isSuperAdmin?: boolean, 
        superTenant?: string | null,
        dataRangeParam?: string
    ){ 
        if (!start_date || !end_date ){
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({data: null});
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

         // Clean and validate parameters
         const cleanParam = (param: any) => {
            if (typeof param === 'string') {
                return param.trim();
            }
            return param;
        };
    
        const cleanedStartDate = cleanParam(start_date);
        const cleanedEndDate = cleanParam(end_date);
        const cleanedTimezone = cleanParam(timezone);
        const cleanedTenantId = tenantId !== null && tenantId !== undefined ? cleanParam(tenantId) : null;
        const cleanedSuperTenant = superTenant !== null && superTenant !== undefined ? cleanParam(superTenant) : null;
    
        const params: any = {
            start_date: cleanedStartDate,
            end_date: cleanedEndDate,
            timezone: cleanedTimezone,
            date_range: dataRangeParam
        };
    
        if (isSuperAdmin && cleanedSuperTenant) {
            params['tenant'] = cleanedSuperTenant.toString();
        } else if (cleanedTenantId) {
            params['tenant'] = cleanedTenantId.toString();
        }

        return this.axiosClient.get(Routes.salesPerformanceReportChartFilterData,{
            params,
            headers: {
                Authorization: `Bearer ${SessionManager.instance().getToken()}`
            },
            signal
        })
    }
    public getSalesPerformanceGraphData(params: URLSearchParams, requestId: string | number | null | undefined){  
        const headers = this.getAuthToken();
        if (!headers) {
            return Promise.reject('Access Token not found');
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.salesPerformanceReportChartData, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Sales Performance Report data:', error);
            throw error;
        });
    }

    public getRetentionData(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.retentionReport, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Retention Report data:', error);
            throw error;
        });
    }

    public getCancellationReasons(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        return this.axiosClient.get(Routes.cancellationReasons, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Cancellation Report data:', error);
            throw error;
        });
    }

    public getCancellationSubscriptionReasons(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        return this.axiosClient.get(Routes.cancellationSubscriptionReasons, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Cancellation Report data:', error);
            throw error;
        });
    }

    public getCancellationChart(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
        
        return this.axiosClient.get(Routes.cancellationChart, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Cancellation Report Chart data:', error);
            throw error;
        });
    }

    public getCancellationSubscriptionChart(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
        
        return this.axiosClient.get(Routes.cancellationSubscriptionChart, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Cancellation Report Chart data:', error);
            throw error;
        });
    }

    public getForecastedRebillsReport(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.forecastedRebills, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching affiliate data:', error);
            throw error;
        });
    }

    public getForecastedRebillsChart(params: URLSearchParams | never[], requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
        
        return this.axiosClient.get(Routes.forecastedRebillsChart, { params, headers, signal }).then(response => {
            let cacheTime = response.headers['x-cache-time'];
            if (!cacheTime) {
                cacheTime = '60000';
            }
            return {
                data: response.data,
                cacheTime: parseInt(cacheTime)
            };
        }).catch(error => {
            console.error('Error fetching Forecasted Rebills Report data:', error);
            throw error;
        });
    }

    public getVarianceReport(params: URLSearchParams | {}) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        const clientNames = ['Yanyi Network Technology', 'Adiom Media Limited', 'Something other', 'Big Company', 'Test Company', 'Something Seller'];
        const hours = [4, 5, 6, 7]; // Specify the hours for which you want to generate data
        const mockData = generateMockVarianceData(clientNames, hours);


        return Promise.resolve(mockData);
    }

    public getDashboardOverviewFilterData(
        start_date: string, 
        end_date: string, 
        timezone: string, 
        requestId: string | number | null | undefined,
        isSuperAdmin?: boolean, 
        superTenant?: string | null,
        tenantId?: string | number | null,
    ){
        if (!start_date || !end_date ){
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({data: null});
        }

        // if (this.controllers[requestId!]) {
        //     this.controllers[requestId!].abort();
        // }

        // const controller = new AbortController();
        // this.controllers[requestId!] = controller;
        // const signal = controller.signal;
    
        // const params: Record<string, string> = {
        //     start_date,
        //     end_date,
        //     timezone,
        // };

        // if (isSuperAdmin && superTenant) {
        //     params['tenant'] = superTenant?.toString();
        // } else if (tenantId) {
        //     params['tenant'] = tenantId?.toString();
        // }
    
        // return this.axiosClient.get(Routes., {
        //     params,
        //     headers: {
        //         Authorization: `Bearer ${SessionManager.instance().getToken()}`,
        //     },
        //     signal
        // }).catch(error => {
        //     console.error('Error fetching Conversion Report data:', error);
        //     throw error;
        // });

        return Promise.resolve(dashboardOverviewFilterData);
    }

    // Fix it when backend will be ready
    public postDashboardOverviewConfig(reportName: string,idName: string[], dateRange:number,offerNames: string[],update: boolean, reportId:number) {
        // const accessToken = localStorage.getItem('accessToken');
        // if (!accessToken){
        //     console.log('Access token not found in localstorage');
        //     return Promise.reject('Access token not found');
        // }

        // const requestBody= {
        //     filters: {
        //         date_range: dateRange,
        //         id_name: idName,
        //         offer_name:offerNames
        //     },
        //     report_name: reportName
        // };
        // const headers = {
        //     Authorization: `Bearer ${accessToken}`
        // };
        // if (update){
        //     return this.axiosClient.put(`${Routes.reportConfig}/${reportId}`, requestBody, {headers})
        //         .then((response)=>{
        //             return response;
        //         })
        //         .catch((error)=>{
        //             return Promise.reject(error);
        //         });
        // }
        // return this.axiosClient.post(Routes.reportConfig, requestBody, {headers})
        //     .then((response)=>{
        //         return response;
        //     })
        //     .catch((error)=>{
        //         return Promise.reject(error);

        //     });
        return Promise.resolve(mockDataConfig);
    }

    public getDashboardOverviewData(params: URLSearchParams, requestId: string | number | null | undefined){
        if (!params || params.size === 0 ){
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({data: null});
        }

        // const headers = this.getAuthToken()
        // if (!headers){
        //     return Promise.reject('Access Token not found')
        // }

        // if (this.controllers[requestId!]) {
        //     this.controllers[requestId!].abort();
        // }

        // const controller = new AbortController();
        // this.controllers[requestId!] = controller;
        // const signal = controller.signal;

        // return this.axiosClient.get(Routes., {params, headers, signal})

        return Promise.resolve(mockDashboardData);
    }


    public getConversionReportFilterData(
        start_date: string, 
        end_date: string, 
        timezone: string, 
        requestId: string | number | null | undefined,
        isSuperAdmin?: boolean, 
        superTenant?: string | null,
        tenantId?: string | number | null,
        dataRangeParam?: string
    ) {
        if (!start_date || !end_date) {
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({ data: null });
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        const params: Record<string, string> = {
            start_date,
            end_date,
            timezone,
        };

        if (isSuperAdmin && superTenant) {
            params['tenant'] = superTenant?.toString();
        } else if (tenantId) {
            params['tenant'] = tenantId?.toString();
        }

        params['date_range'] = dataRangeParam!.toString();
    
        return this.axiosClient.get(Routes.conversationReportFilterData, {
            params,
            headers: {
                Authorization: `Bearer ${SessionManager.instance().getToken()}`,
            },
            signal
        }).catch(error => {
            console.error('Error fetching Conversion Report data:', error);
            throw error;
        });
    }

    public getConversationReportData(params: URLSearchParams, isDetailsActiveTab: boolean, requestId: string | number | null | undefined,) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        const url = isDetailsActiveTab ? Routes.conversationReportData : Routes.conversationReportMetrics;

        return this.axiosClient.get(url, {params, headers, signal})
    }

    public getDeclineResponseReportData(params: URLSearchParams, requestId: string | number | null | undefined) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        return this.axiosClient.get(Routes.declineResponseReport, {params, headers, signal})

        // return new Promise((resolve) => {
        //     // @ts-ignore
        //     resolve({ data: mockDataDeclineResponeReport });
        // });
    }

    public saveCsvExport(report_id: ReportIds | undefined, filters: string[] | undefined, start_date: string, end_date: string, namesEnum: { [key: string]: string }) {

        const getFiltersString = (filters: string[] | undefined) => {
            if (!filters || filters.length === 0) {
                return '';
            }
            return filters.map((filter, index) => `filters[${index}]=${(namesEnum)[filter]}`).join('&');
        }

        const headers = {
            Authorization: `Bearer ${SessionManager.instance().getToken()}`
        };

        const url = `${Routes.saveCsvExport}?activity=${UserActivityEnum.CSV_EXPORT}&report_id=${report_id}&${getFiltersString(filters)}&date_range[start_date]=${start_date}&date_range[end_date]=${end_date}`;
        return this.axiosClient.post(url, {}, { headers });
    }

    public updateProfileImage(image: Blob) {

        const headers = {
            Authorization: `Bearer ${SessionManager.instance().getToken()}`,
            'Content-Type': 'multipart/form-data'
        };

        const formData = new FormData();
        formData.append('profile_picture', image);

        const url = `${Routes.updateProfilePicture}`;

        return this.axiosClient.post(url, formData, { headers });
    }

    public deleteProfileImage() {
        const headers = {
            Authorization: `Bearer ${SessionManager.instance().getToken()}`
        };
    
        const url = `${Routes.updateProfilePicture}`;
    
        return this.axiosClient.delete(url, { headers });
    }

    public checkToken() {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access token not found');
        }

        const url = `${Routes.checkToken}`

        return this.axiosClient.get(url, {headers})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error)
            });
    }

    public getTenantList() {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        return this.axiosClient.get(Routes.getTenants, {headers})
    }

    public getBinRoutingPerformanceReportData(
        params: URLSearchParams,
        requestId: string | number | null | undefined
    ): Promise<{ data: BinRoutingPerformaceReportResponse }> {
        // console.log('params', params);
        // return new Promise((resolve) => {
        //     // @ts-ignore
        //     resolve({ data: mockReportsResponse });
        // });
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        const url = Routes.binRoutingPerformaceReport;

        return this.axiosClient.get(url, {params, headers, signal})
    }

    public getChargebackSummaryReportData(params: URLSearchParams, requestId: string | number | null | undefined) {
        // const headers = this.getAuthToken()
        // if (!headers){
        //     return Promise.reject('Access Token not found')
        // }

        // if (this.controllers[requestId!]) {
        //     this.controllers[requestId!].abort();
        // }

        // const controller = new AbortController();
        // this.controllers[requestId!] = controller;
        // const signal = controller.signal;

        // return this.axiosClient.get(Routes., {params, headers, signal})

        return new Promise((resolve) => {
            resolve({ data: mockDataChargebackSummaryReport });
        });
    }

    public getVarianceReportFilterData(
        start_date: string, 
        end_date: string, 
        timezone: string, 
        requestId: string | number | null | undefined,
        isSuperAdmin?: boolean, 
        superTenant?: string | null,
        tenantId?: string | number | null,
        dataRangeParam?: string
    ) : Promise<{ data: {
        categories: string[] | null
    } | null }> {
        if (!start_date || !end_date) {
            console.warn('No parameters provided. Skipping API call.');
            return Promise.resolve({ data: null });
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;
    
        const params: Record<string, string> = {
            start_date,
            end_date,
            timezone,
        };

        if (isSuperAdmin && superTenant) {
            params['tenant'] = superTenant?.toString();
        } else if (tenantId) {
            params['tenant'] = tenantId?.toString();
        }

        params['date_range'] = dataRangeParam!.toString();
    
        return this.axiosClient.get(Routes.varianceReportFilterData, {
            params,
            headers: {
                Authorization: `Bearer ${SessionManager.instance().getToken()}`,
            },
            signal
        }).catch(error => {
            console.error('Error fetching Conversion Report data:', error);
            throw error;
        });
        // return new Promise((resolve) => {
        //     // @ts-ignore
        //     resolve({ data: filterMockData });
        // });
    }

    public getVarianceReportData(params: URLSearchParams, requestId: string | number | null | undefined,) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        const url = Routes.varianceReportData;

        return this.axiosClient.get(url, {params, headers, signal})

        // return new Promise((resolve) => {
        //     // mockDataHours
        //     // mockDataPartner
        //     // newMockDataVarianceReport
        //     // @ts-ignore
        //     resolve({ data: newMockDataVarianceReport });
        // });
    }

    public getCardProcessingReportData(params: URLSearchParams, requestId: string | number | null | undefined,) {
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        const url = Routes.cardProcessingData;

        return this.axiosClient.get(url, {params, headers, signal})

        // return new Promise((resolve) => {
        //     // @ts-ignore
        //     resolve({ data: mockDataCardProcessingReport });
        // });
    }

    public getDuplicateCustomerReportData(
        params: URLSearchParams,
        requestId: string | number | null | undefined
    ): Promise<{ data: DuplicateCustomerReportResponse }> {
        // return new Promise((resolve) => {
        //     // @ts-ignore
        //     resolve({ data: duplicateCustomerReportMockData });
        // });
        const headers = this.getAuthToken()
        if (!headers){
            return Promise.reject('Access Token not found')
        }

        if (this.controllers[requestId!]) {
            this.controllers[requestId!].abort();
        }

        const controller = new AbortController();
        this.controllers[requestId!] = controller;
        const signal = controller.signal;

        const url = Routes.duplicateCustomerReport;

        return this.axiosClient.get(url, {params, headers, signal})
    }
}

export default OdinForgeService;


