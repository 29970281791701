import { CardProcessingReportData, RestType, TransformedData } from "./types/card-processing-report.types";

export const transformDataToCSVFormat = (
    data: CardProcessingReportData[], 
    namesEnum: Partial<Record<keyof RestType, string>>, 
    fieldsForCSV: { [key: string]: string; }
): TransformedData[] => {
    // Utility function to format numbers and return '---' for null or undefined
    const formatNumber = (value: number | null | undefined): string => {
        return value === null || value === undefined ? '---' : Number(value).toLocaleString();
    };

    // Map over the data to create transformedData array
    const transformedData = data.map((item): TransformedData => {
        const { name, attempts, success, failures, ...rest } = item;

        // Calculate success rate
        const successRate = attempts ? ((success / attempts) * 100).toFixed(2) : '0';
        const formattedSuccessRate = `${Number(successRate).toLocaleString()}%`;

        // Get the grouping key from fieldsForCSV or default to 'Grouping'
        const groupingKey = fieldsForCSV['grouping'] || 'Grouping';

        // Rename the rest of the fields according to namesEnum and format the specific fields
        const renamedRest = Object.keys(rest).reduce((acc: { [key: string]: RestType[keyof RestType] | string }, key) => {
            const typedKey = key as keyof RestType;
            const newKey = namesEnum[typedKey] || key; // Use mapped name or original key

            // Add the rest of the fields without any additional processing
            // @ts-ignore
            acc[newKey] = rest[typedKey];
            return acc;
        }, {} as { [key: string]: RestType[keyof RestType] | string });

        // Construct the new item with the formatted values
        const newItem: TransformedData = {
            [groupingKey]: name, // Set the grouping key
            [namesEnum['attempts'] || 'Attempts']: formatNumber(attempts),
            [namesEnum['success'] || 'Success']: formatNumber(success),
            [namesEnum['failures'] || 'Failures']: formatNumber(failures),
            [namesEnum['success_rate'] || 'Success Rate']: formattedSuccessRate,
            ...renamedRest // Add any other fields present in the item
        };

        return newItem;
    });

    return transformedData;
};
