import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserContext/UserContext';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

const DateTimeTimezone = () => {
  const userContext = useContext(UserContext)!;
  const { nonCanonicalTimezone, tenantId, superTenant } = userContext;
  const { themedColors } = useContext(ThemeContext)!;

  // State to hold the current time
  const [userTime, setUserTime] = useState('');

  useEffect(() => {
    // Function to update the time
    const updateTime = () => {
      const currentTime = dayjs().tz(nonCanonicalTimezone).format('DD-MMM-YY HH:mm');
      setUserTime(currentTime);
    };

    // Update time initially
    updateTime();

    // Set up interval to update time every minute
    const intervalId = setInterval(updateTime, 60000); // Update every 60,000 milliseconds (1 minute)

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [nonCanonicalTimezone]);

  const timezoneCity = nonCanonicalTimezone?.split('/')?.pop()!.replace('_', ' ');

  return (
    <div
        className='text-xs'
        style={{
            color: themedColors.content
        }}
    >
      {
        superTenant ? (
          <p>Tenant: {`${superTenant}`}</p>
        ) : tenantId ? (
          <p>Tenant: {`${tenantId}`}</p>
        ) : null
      }
      <p>{`${userTime}, ${timezoneCity}`}</p>
    </div>
  );
}

export default DateTimeTimezone;
