export const columnWidth = {
    1: 350,
    2: 390,
    3: 430,
    4: 470,
    5: 510,
    6: 550,
    7: 590,
    8: 630
}

export const getFirsrColimnWidth = (selectedGroupings: number, groupIndex: number) => {
    return `${(columnWidth as { [key: number]: number })[selectedGroupings] - 150 - (groupIndex * 26)}px`
}

export const getLoadMoreBtnWidth = (selectedGroupings: number, groupIndex: number) => {
    return `${(columnWidth as { [key: number]: number })[selectedGroupings] - (groupIndex * 30)}px`
}