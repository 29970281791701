import React, {useContext, useEffect, useRef, useState} from 'react';
import DashboardFilterBar from './components/DashboardFilterBar'
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import Utils from "../../../../odinForgeService/Utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs, { Dayjs } from "dayjs";
import "../../../../css/EmptyReport.css"
import { SelectChangeEvent } from '@mui/material';
import DashboardOverviewReport from './components/DashboardOverviewReport';
import {formatLocalISO} from "../../../../helpers/formatLocalISO";
import DefaultTableMessage from '../../../organism/DefaultTableMessage/DefaultTableMessage';
import { UserContext } from '../../../../context/UserContext/UserContext';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DashboardOverviewProps {
    passData: (data: any) => void
    passId: (id: string | number | null | undefined) => void
    clonedData: any,
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const DashboardOverview = ({
    passData,
    passId,
    clonedData,
    setRefreshKey,
    refreshKey,
}: DashboardOverviewProps) => {
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;

    const emptyArray: string[]=[];
    const [campaignName, setCampaignName] = useState<string[]>(clonedData?.id_name ?? []);
    const [affiliateName, setAffiliateName] = useState<string[]>(clonedData?.offer_name ?? []);
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence']?clonedData['group_sequence']:[]);
    let lineChartParams = new URLSearchParams();

    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))

    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())

    const [campaignOptions, setCampaignOptions] = useState<string[]>([]);
    const [affiliateOptions, setAffiliateOptions] = useState<string[]>();

    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:0);
   
    const [datesApplyButton, setDatesApplyButton] = useState(true);
    const [enableFiltersApplyButton, setEnableFiltersApplyButton] = useState(true);

    const [datesPreviousStartDate, setDatesPreviousStartDate] = useState(startDate);
    const [datesPreviousEndDate, setDatesPreviousEndDate] = useState(endDate);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const reportName = ReportName.DashboardOverview;

    const [copyButton, setCopyButton] = React.useState(!(clonedData));
    const [clonedReportId] =useState(clonedData.id?clonedData.id:0)
    const [deletePopup, setDeletePopup] = useState(false);

    const [showDelete] = useState(clonedData.type === 'cloned'?clonedData.type === 'cloned':false);
    const firstCall = useRef(true)

    const [data, setData] = React.useState<any>([]);

    const [isTableLoading, setIsTableLoading] = useState(false);
    const [loaderKey, setLoaderKey] = useState(0);

    const [selectedGroupings] = React.useState(3);
    const groupSequence = groupings;

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate,
    });

    const reportConfigValues = useRef({ reportName, groupSequence });

    const handleIdSelectChange = (event: SelectChangeEvent<typeof campaignName>) => {
        const {
            target: {value},
        } = event;
        setCampaignName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleOfferSelectChange = (event: SelectChangeEvent<typeof affiliateName>) => {
        const {
            target: {value},
        } = event;
        setAffiliateName(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.dateRange !== dateRange ||
            previousValues.startDate !== startDate ||
            previousValues.endDate !== endDate
        )) {
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                dateRange: dateRange,
                startDate: startDate,
                endDate: endDate,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate]);

    const getFilterData = async () => {

        const formattedStartDate = formatLocalISO((startDate as Dayjs).set('second', 0o0));
        const formattedEndDate = formatLocalISO((endDate as Dayjs).set('second', 59));
        try {
            setIsTableLoading(true);
            const response = await OdinForgeService.instance().getDashboardOverviewFilterData(formattedStartDate, formattedEndDate, timezone, `${clonedReportId}-filters` , isSuperAdmin, superTenant, tenantId);
            // const responseData = response.data;
            const responseData = response;
            let mappedIdOptions: string[] = [];
            if ('id_name' in responseData) {
                mappedIdOptions = responseData.id_name.map(item => item.name);
                setCampaignOptions(mappedIdOptions);
            }

            if ('offer' in responseData) {
                const fetchedOfferOptions: string[] = responseData.offer.map((item: any) => item.name);
                setAffiliateOptions(fetchedOfferOptions);
            }

            setDatesApplyButton(true);

            if (firstCall.current){
                await handleBlur()
                firstCall.current = false
            }
            if(dateRange !== clonedData.date_range || startDate !== previousStartDate) {
                setCampaignName(emptyArray);
                setAffiliateName(emptyArray);
            }

        } catch (error) {
            Utils.instance().onFailure("Error fetching graph data");
        } finally {
            setIsTableLoading(false);
            setDatesPreviousEndDate(endDate)
            setDatesPreviousStartDate(startDate)
        }
    }

    const generateParams = () => {
        lineChartParams = new URLSearchParams();
        lineChartParams.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        lineChartParams.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        let filters: string[] = []
        campaignName.forEach((value: PropertyKey) => {
            filters.push((value as any));
        })

        lineChartParams.append('id_names[]', '');
        filters.forEach((value, index) => {
            lineChartParams.append(`id_names[${index}]`, value)
        })
        affiliateName.forEach((value, index) => {
            lineChartParams.append(`offers[${index}]`, value)
        })

        lineChartParams.append('timezone', timezone) 
        
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && lineChartParams.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && lineChartParams.append('tenant', tenantId?.toString())
        }
    };

    function checkIfSelectedFiltersEmpty() {
        let result = true

        if (campaignName.length !== 0 || affiliateName.length !== 0) {
            result = true;
        } else {
            result = false;
        }

        return result
    }

    function generateDataToHash() {
        const dataToHash = {
            campaignName: campaignName,
            affiliateName: affiliateName,
            startDate: startDate,
            endDate: endDate,
        }

        return dataToHash;
    }

    const handleBlur = async () => {
        try {
            setData([]);
            if (checkIfSelectedFiltersEmpty()) {
                setIsTableLoading(true);
                generateParams()
                const cachedData = Utils.instance().checkHash(generateDataToHash(), reportName)

                
                if (cachedData){
                    setData(cachedData);          
                } else {
                    const response = await OdinForgeService.instance().getDashboardOverviewData(lineChartParams, clonedReportId);
                    // uncomment when backend is ready
                    // const responseData = response.data;
                    const responseData = response;

                    setData(responseData); 
                    
                    Utils.instance().createHash(generateDataToHash(), responseData, reportName)
                    
                }
            }
            else {
                // @ts-ignore
                if (error.code === 'ERR_CANCELED') {
                    setLoaderKey(prevKey => prevKey + 1);
                    console.log('Request canceled');
                } else {
                    setIsTableLoading(false);
                    Utils.instance().onFailure('An error occurred while fetching the report');
                }
            }
        } catch (error) {
            // Utils.instance().onFailure("There was an error fetching the graph data")
        } finally {
            setIsTableLoading(false);
            setPreviousStartDate(startDate)
        }
    }

    useEffect(() => {
        if (
            (startDate as Dayjs).format('YYYY-MM-DD') === (datesPreviousStartDate as Dayjs).format('YYYY-MM-DD')
            && (endDate as Dayjs).format('YYYY-MM-DD') === (datesPreviousEndDate as Dayjs).format('YYYY-MM-DD')
        )
        {
            setDatesApplyButton(true)
        }
        else {
            setDatesApplyButton(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        let isFilterSelected = (campaignName.length > 0 && affiliateName.length > 0);
        let buttonVisiblity = isFilterSelected;
        setEnableFiltersApplyButton(buttonVisiblity);

    }, [campaignName, affiliateName, startDate, endDate]);

    const filterConfig = [
        { label: "Campaign", name: "campaignName", options: campaignOptions, itemName: campaignName, handleSelectChange: handleIdSelectChange },
        { label: "Affiliate", name: "affiliateName", options: affiliateOptions, itemName: affiliateName, handleSelectChange: handleOfferSelectChange },
    ];

    const clearOptions = () => {
        setCampaignName(emptyArray);
        setAffiliateName(emptyArray);
    }
  
    function generateConfigRequestBody() {
        return {
            filters: {
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
            },
            report_name: reportName
        }
    }
    
    function generateClonedData(reportId: number)  {
        const dataToPass = {
            id: reportId,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
        }
        passData(dataToPass)
    }

    useEffect(() => {
        getFilterData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant]);

  return (
    <div className={"pb-10  min-h-[450px]"} >
        <div className="!p-6 tablet-size:!p-0">
            <DashboardFilterBar
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                affiliateName={affiliateName}
                getLineChartData={handleBlur}
                passData={passData}
                clonedReportId={clonedReportId}
                passId={passId}
                setDeletePopup={setDeletePopup}
                deletePopup={deletePopup}
                showDelete={showDelete}
                datesApplyButton={datesApplyButton}
                getFilterData={getFilterData}
                copyButton={copyButton}
                reportName={reportName}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                enableFiltersApplyButton={enableFiltersApplyButton}
                filterConfig={filterConfig}
                title={ReportName.DashboardOverview}  
                clearOptions={clearOptions}    
                // Refresh cloneData
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}  
                //
                freezeButton={freezeButton}
                setFreezeButton={setFreezeButton}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                data={data}
            />
        </div>
        {
            data && data.length !== 0 ? (
                    <DashboardOverviewReport data={data} />) :  (
                <div className="mt-6">
                    <DefaultTableMessage
                        key={loaderKey}
                        tableLoading={isTableLoading}
                        state={'noData'}
                    />
                </div>
            )
        }

    </div>
  )
}

export default DashboardOverview