export const mockDataConfig = {
    "current_page": 1,
    "data": [
      {
        "id": 62,
        "group_sequence": "{\"date_range\":0,\"id_name\":[\"Geo\"],\"offer_name\":[]}",
        "report_name": "Dashboard Overview",
        "type": "default",
        "created_at": "2024-02-11T18:36:47.000000Z",
        "updated_at": "2024-02-23T13:25:41.000000Z"
      }
    ],
    "first_page_url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
    "from": 1,
    "last_page": 1,
    "last_page_url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
    "links": [
      {
        "url": null,
        "label": "&laquo; Previous",
        "active": false
      },
      {
        "url": "https://staging-api.odinforgebi.com/api/report/config/search?page=1",
        "label": "1",
        "active": true
      },
      {
        "url": null,
        "label": "Next &raquo;",
        "active": false
      }
    ],
    "next_page_url": null,
    "path": "https://staging-api.odinforgebi.com/api/report/config/search",
    "per_page": 10,
    "prev_page_url": null,
    "to": 1,
    "total": 1
}

export const dashboardOverviewFilterData = {
    "id_name": [
        {
            "id": 1,
            "name": "Geo"
        },
        {
            "id": 2,
            "name": "Passval1"
        },
    ],
    "offer": [
        {
            "id": 1,
            "name": "Keto 1"
        },
        {
            "id": 2,
            "name": "Keto 2"
        },
        {
            "id": 3,
            "name": "Keto 3"
        },
        {
            "id": 4,
            "name": "Keto 4"
        },
        {
            "id": 5,
            "name": "Keto 5"
        
        }
    ],
}

export const mockDashboardData = {
    "approval_rate": {
      "amount": "40.04",
      "partials": 861,
      "partials_rate": "70",
      "sales": 575,
      "sales_rate": "25",
      "declines": 38,
      "declines_rate": "5",
    },
    "average_order_value": {
      "amount": "230",
      "currency": "usd",
    },
    "credit_card_overview": [
      {
        "name": "0",
        "count": 7,
        "ratio": "1.22"
      },
      {
        "name": "MasterCard",
        "count": 267,
        "ratio": "46.43"
      },
      {
        "name": "Visa",
        "count": 274,
        "ratio": "47.65"
      }
    ],
    "credit_card_decline": [
      {
        "name": "0",
        "count": 5,
        "ratio": "1.30"
      },
      {
        "name": "AMEX",
        "count": 13,
        "ratio": "3.37"
      },
      {
        "name": "DISCOVER",
        "count": 17,
        "ratio": "4.40"
      },
      {
        "name": "MASTERCARD",
        "count": 79,
        "ratio": "20.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
      {
        "name": "VISA",
        "count": 272,
        "ratio": "70.47"
      },
    ],
    "product_breakdown": [
      {
        "name": "Keto-2 Bottles",
        "count": 86,
        "ratio": "14.96"
      },
      {
        "name": "Keto-4 Bottles",
        "count": 82,
        "ratio": "14.26"
      },
      {
        "name": "Keto-6 Bottles",
        "count": 380,
        "ratio": "66.08"
      },
      {
        "name": "Keto-6 Bottles",
        "count": 380,
        "ratio": "66.08"
      }
    ],
    "misc_stats_overview": [
      {
        "name": "Orders with same IP",
        "count": 7,
        "ratio": "1.22"
      },
      {
        "name": "Zip Match",
        "count": 267,
        "ratio": "46.43"
      },
      {
        "name": "Area Code Match",
        "count": 274,
        "ratio": "47.65"
      },
      {
        "name": "Void/Cancelled Orders",
        "count": 5,
        "ratio": "1.30"
      },
      {
        "name": "PARTIAL Refund Percent",
        "count": 5,
        "ratio": "1.30"
      },
      {
        "name": "FULL Refund Percent",
        "count": 13,
        "ratio": "3.37"
      },
      {
        "name": "Number of Pasted Entries",
        "count": 17,
        "ratio": "4.40"
      }
    ]
  }
  