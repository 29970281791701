import { DeclineResponseReport } from "../types/decline-response-report.types";

function searchRow(data: any, searchTerm: string): any {
    if (Array.isArray(data)) {
        // Обрабатываем каждый элемент массива
        const filteredArray = data
            .map(item => searchRow(item, searchTerm))
            .filter(item => item !== null);
        return filteredArray.length > 0 ? filteredArray : null;
    } else if (typeof data === 'object' && data !== null) {
        const newData: any = { ...data };

        // Проверяем, содержит ли поле 'name' искомый термин
        if (newData.name && newData.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            // Если совпадение найдено, возвращаем текущий узел со всеми дочерними элементами
            return newData;
        }

        let matchFound = false;

        for (const key in newData) {
            const child = newData[key];

            if (typeof child === 'object' && child !== null) {
                const filteredChild = searchRow(child, searchTerm);
                if (filteredChild !== null) {
                    newData[key] = filteredChild;
                    matchFound = true;
                } else {
                    delete newData[key];
                }
            }
            // Если значение не объект или массив, оставляем его без изменений
        }

        // Если есть совпадение в дочерних элементах, возвращаем текущий узел со всеми его данными
        if (matchFound) {
            return newData;
        } else {
            // Если совпадений нет, возвращаем null
            return null;
        }
    } else {
        // Если это не объект и не массив, возвращаем null
        return null;
    }
}

export default searchRow;

export function searchRowDeclineReport(data: any[], searchTerm: string, dynamicKey: string): DeclineResponseReport[] {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const result: DeclineResponseReport[] = [];
  
    for (const item of data) {
      const newItem = { ...item };
  
      // Проверяем совпадение на первом уровне по динамическому ключу
      if (
        newItem[dynamicKey] &&
        typeof newItem[dynamicKey] === 'string' &&
        newItem[dynamicKey].toLowerCase().includes(lowerCaseSearchTerm)
      ) {
        // Совпадение найдено, добавляем весь объект в результат
        result.push(newItem);
        continue;
      }
  
      // Ищем совпадения внутри subCategories
      if (Array.isArray(newItem.subCategories)) {
        const filteredSubCategories = newItem.subCategories.filter((subItem: any) => {
          return (
            subItem.reason &&
            typeof subItem.reason === 'string' &&
            subItem.reason.toLowerCase().includes(lowerCaseSearchTerm)
          );
        });
  
        if (filteredSubCategories.length > 0) {
          // Совпадения найдены, обновляем subCategories и добавляем объект в результат
          newItem.subCategories = filteredSubCategories;
          result.push(newItem);
        }
      }
    }
  
    return result.length > 0 ? result : [];
}

export function searchRowForecasedRebills(data: any, searchTerm: string): any {
    if (Array.isArray(data)) {
        const filteredArray = data
            .map(item => searchRowForecasedRebills(item, searchTerm))
            .filter(item => item !== null);
        return filteredArray.length > 0 ? filteredArray : null;
    } else if (typeof data === 'object' && data !== null) {
        let matchFound = false;

        // Проверяем, содержит ли текущий узел искомый термин
        if (data.name && data.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            matchFound = true;
        }

        const newData: any = { ...data };

        for (const key in data) {
            const child = data[key];
            if (typeof child === 'object' && child !== null) {
                const filteredChild = searchRowForecasedRebills(child, searchTerm);
                if (filteredChild !== null) {
                    newData[key] = filteredChild;
                    matchFound = true;
                } else {
                    // Решаем, удалять свойство или нет
                    if (key === 'stats') {
                        // Оставляем 'stats' даже если совпадений нет
                        newData[key] = child;
                    } else if (Array.isArray(child)) {
                        // Удаляем массивы, если в них нет совпадений
                        delete newData[key];
                    } else {
                        // Оставляем другие объекты (например, 'id', 'currency')
                        newData[key] = child;
                    }
                }
            }
            // Если значение не объект и не массив, оставляем его без изменений
        }

        if (matchFound) {
            return newData;
        } else {
            return null;
        }
    } else {
        return null;
    }
}
